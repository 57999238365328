var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function (window, angular, undefined) {
  'use strict';


  angular.module('DuplicateRequestsFilter.Decorator', [])

  .config(['$provide', function ($provide) {

    $provide.decorator('$http', ['$delegate', '$q', function ($delegate, $q) {

      var pendingRequests = {};
      var $http = $delegate;

      function hash(str) {
        var h = 0;
        var strlen = str.length;
        if (strlen === 0) {
          return h;
        }
        for (var i = 0, n; i < strlen; ++i) {
          n = str.charCodeAt(i);
          h = (h << 5) - h + n;
          h = h & h;
        }
        return h >>> 0;
      }

      function getRequestIdentifier(config) {
        var str = config.method + config.url;
        if (config.params && _typeof(config.params) === 'object') {
          str += angular.toJson(config.params);
        }
        if (config.data && _typeof(config.data) === 'object') {
          str += angular.toJson(config.data);
        }
        return hash(str);
      }

      function $duplicateRequestsFilter(config) {

        if (config.ignoreDuplicateRequest) {
          return $http(config);
        }

        var identifier = getRequestIdentifier(config);

        if (pendingRequests[identifier]) {
          if (config.rejectDuplicateRequest) {
            return $q.reject({
              data: '',
              headers: {},
              status: config.rejectDuplicateStatusCode || 400,
              config: config
            });
          }
          return pendingRequests[identifier];
        }

        pendingRequests[identifier] = $http(config).finally(function () {
          delete pendingRequests[identifier];
        });

        return pendingRequests[identifier];
      }

      Object.keys($http).filter(function (key) {
        return typeof $http[key] === 'function';
      }).forEach(function (key) {
        return $duplicateRequestsFilter[key] = $http[key];
      });

      $duplicateRequestsFilter.defaults = $http.defaults;

      return $duplicateRequestsFilter;
    }]);
  }]);
})(window, window.angular);