(function (window, angular, undefined) {
  'use strict';


  angular.module('Store.Service', ['Store.BaseStore.Service', 'Store.CollectionStore.Service', 'Store.InstanceStore.Service'])

  .provider('$store', function $storeProvider() {

    this.defaults = {
      model: '',
      methods: null,
      service: '$collectionStore',
      verbose: false
    };

    this.collections = {};

    this.setVerbose = function (verbose) {
      this.defaults.verbose = !!verbose;
      return this;
    };

    this.registerStore = function (name, config) {
      if (name) {
        this.collections[name] = config || {};
      }
      return this;
    };

    this.$get = ['$log', '$injector', function ($log, $injector) {

      var Store = function Store(store) {
        return this[store];
      };

      angular.forEach(this.collections, function (config, name) {

        config = angular.extend({}, this.defaults, config);

        if (config.verbose) {
          $log.info('Store', name + ':', config);
        }

        if (!config.service || !$injector.has(config.service)) {
          return $log.error('Unknown service', config.service, 'specified for', name, 'store');
        }

        if (!config.model || !$injector.has(config.model)) {
          return $log.error('Unknown model specified for', name, 'store:', config.model);
        }

        var StoreService = $injector.get(config.service);
        var StoreInstance = new StoreService(name, config);

        if (Store[name]) {
          $log.warn('Store', name, 'is being overwritten.');
        }

        Store[name] = StoreInstance;
      }, this);

      return Store;
    }];
  });
})(window, window.angular);
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj; };

(function (window, angular, undefined) {
  'use strict';


  angular.module('Store.BaseStore.Service', [])

  .factory('$baseStore', ['$q', '$log', '$injector', function $baseStore($q, $log, $injector) {

    function validateModel(item, Model, convert) {
      if (item instanceof Model) {
        return item;
      }
      if (convert && (typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object') {
        return new Model(item);
      }
      return null;
    }

    function BaseStore(name, config) {

      this.name = name;
      this.config = config;

      var Model = null;
      Object.defineProperty(this, 'model', {
        get: function get() {
          Model = Model || $injector.get(config.model);
          return Model;
        }
      });
    }

    BaseStore.prototype.warnMissingMethod = function (method) {
      $log.warn('No static `' + method + '` method present on model for', this.name, 'store');
    };

    BaseStore.prototype.validateIsModel = function (item, convert) {
      var _this = this;

      if (angular.isArray(item)) {
        var models = item.map(function (item) {
          return validateModel(item, _this.model, convert);
        }).filter(function (item) {
          return !!item;
        });
        return $q.resolve(models);
      }

      var model = validateModel(item, this.model, convert);
      if (model !== null) {
        return $q.resolve(model);
      }
      $log.warn('Invalid object passed to', this.name, 'store as model:', item);
      return $q.reject('Invalid model');
    };

    return BaseStore;
  }]);
})(window, window.angular);
(function (window, angular, undefined) {
  'use strict';


  angular.module('Store.CollectionStore.Service', ['Store.BaseStore.Service'])

  .factory('$collectionStore', ['$q', '$log', '$baseStore', function $collectionStore($q, $log, $baseStore) {

    function CollectionStore(name, config) {

      $baseStore.call(this, name, config);

      this.collection = new Map();
      this.isLoaded = false;
      this.cacheEmpty = config.cacheEmpty || false;
    }

    angular.extend(CollectionStore.prototype, $baseStore.prototype);

    CollectionStore.prototype.query = function (filter, refresh) {
      var _this = this;

      if (typeof filter === 'boolean') {
        refresh = filter;
        filter = null;
      }

      if (this.isLoaded && !filter && !refresh) {
        return $q.resolve(Array.from(this.collection.values()));
      }

      if (!angular.isFunction(this.model.query)) {
        this.warnMissingMethod('query');
        return $q.resolve([]);
      }

      var dataKey = this.config.dataKey;


      return this.model.query(filter).then(function (data) {
        if (dataKey && Array.isArray(data[dataKey])) {
          return data[dataKey];
        } else if (Array.isArray(data)) {
          return data;
        }
        throw new Error('Unexpected data format for ' + _this.name + ' store');
      }).then(function (items) {

        items.forEach(function (item) {
          return _this.add(item);
        });

        if (!filter && (items.length > 0 || _this.cacheEmpty)) {
          _this.isLoaded = true;
        }

        return items;
      });
    };

    CollectionStore.prototype.clear = function () {
      this.collection.clear();
      this.isLoaded = false;
      return $q.resolve();
    };

    CollectionStore.prototype.has = function (id) {
      return this.collection.has(id);
    };

    CollectionStore.prototype.findById = function (id, refresh) {
      var _this2 = this;

      if (!refresh && this.collection.has(id)) {
        return $q.resolve(this.collection.get(id));
      }

      if (!angular.isFunction(this.model.findById)) {
        this.warnMissingMethod('findById');
        return $q.resolve(null);
      }

      return this.model.findById(id).then(function (item) {
        return _this2.add(item);
      });
    };

    CollectionStore.prototype.save = function (item, data) {
      var _this3 = this;

      return this.validateIsModel(item, true).then(function (item) {
        return item.save(data);
      }).then(function (item) {
        return _this3.add(item);
      });
    };

    CollectionStore.prototype.delete = function (item) {
      var _this4 = this;

      return this.validateIsModel(item).then(function (item) {
        return item.delete();
      }).then(function (item) {
        return _this4.remove(item);
      });
    };

    CollectionStore.prototype.load = function (items) {
      var _this5 = this;

      items = items || [];
      return this.validateIsModel(items, true).then(function (items) {
        return _this5.add(items);
      }).finally(function () {
        _this5.isLoaded = true;
      });
    };


    CollectionStore.prototype.add = function (item) {
      var _this6 = this;

      if (angular.isArray(item)) {
        item.forEach(function (item) {
          return _this6.collection.set(item.id, item);
        });
      } else {
        if (typeof item.id === 'undefined') {
          $log.warn('Trying to add item to', this.name, ' store,', 'but no `id` property present on item:', item);
          return $q.reject();
        }
        this.collection.set(item.id, item);
      }
      return $q.resolve(item);
    };

    CollectionStore.prototype.remove = function (item) {
      var _this7 = this;

      if (angular.isArray(item)) {
        item.forEach(function (item) {
          return _this7.collection.delete(item.id);
        });
      } else {
        if (typeof item.id === 'undefined') {
          $log.warn('Trying to remove item from', this.name, ' store,', 'but no `id` property present on item:', item);
          return $q.reject();
        }
        this.collection.delete(item.id);
      }
      return $q.resolve(item);
    };

    return CollectionStore;
  }]);
})(window, window.angular);
(function (window, angular, undefined) {
  'use strict';


  angular.module('Store.InstanceStore.Service', ['Store.BaseStore.Service'])

  .factory('$instanceStore', ['$q', '$baseStore', function $instanceStore($q, $baseStore) {

    function InstanceStore(name, config) {

      $baseStore.call(this, name, config);

      this.instance = null;
      this.promise = null;
      this.cacheEmpty = config.cacheEmpty || false;
    }

    angular.extend(InstanceStore.prototype, $baseStore.prototype);

    InstanceStore.prototype.get = function (filter, refresh) {
      var _this = this;

      if (typeof filter === 'boolean') {
        refresh = filter;
        filter = null;
      }

      if ((this.instance || this.isLoaded && this.cacheEmpty) && !refresh) {
        return $q.resolve(this.instance);
      }

      if (this.promise) {
        return this.promise;
      }

      if (!angular.isFunction(this.model.get)) {
        this.warnMissingMethod('get');
        return $q.resolve([]);
      }

      this.promise = this.model.get(filter).then(function (instance) {
        _this.isLoaded = true;
        _this.instance = instance;
        return instance;
      }).finally(function () {
        return _this.promise = null;
      });

      return this.promise;
    };

    InstanceStore.prototype.set = function (instance) {
      var _this2 = this;

      return this.validateIsModel(instance, true).then(function (instance) {
        _this2.isLoaded = true;
        _this2.instance = instance;
        return instance;
      });
    };

    InstanceStore.prototype.clear = function () {
      this.instance = null;
      this.isLoaded = false;
      return $q.resolve();
    };

    InstanceStore.prototype.save = function (item, data) {
      return this.validateIsModel(item, true).then(function (item) {
        return item.save(data);
      });
    };

    return InstanceStore;
  }]);
})(window, window.angular);