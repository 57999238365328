(function (window, angular, undefined) {
  'use strict';


  angular.module('Url.Service', [])

  .factory('$url', function () {
    return {

      concat: function concat() {
        var parts = [];
        for (var i = 0; i < arguments.length; i++) {
          var part = arguments[i];
          if (part === null || part === undefined || part === true || part === false) {
            continue;
          }
          part = String(part);
          if (part !== '') {
            parts.push(part);
          }
        }
        return parts.join('/').replace(/([^:])(\/\/+)/g, '$1/').replace(/^\/\//, '/');
      },

      encodeUriComponent: function encodeUriComponent(str, pctEncodeSpaces) {
        return encodeURIComponent(str).replace(/%40/g, '@').replace(/%3A/gi, ':').replace(/%24/g, '$').replace(/%2C/gi, ',').replace(/%20/g, pctEncodeSpaces ? '%20' : '+');
      },

      encodeUriSegment: function encodeUriSegment(str) {
        return this.encodeUriComponent(str, true).replace(/%26/gi, '&').replace(/%3D/gi, '=').replace(/%2B/gi, '+');
      }
    };
  });
})(window, window.angular);