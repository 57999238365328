(function (window, angular, undefined) {
  'use strict';


  angular.module('Convert.Service', ['Convert.StringConverter.Service', 'Convert.ObjectConverter.Service', 'Convert.QueryStringConverter.Service'])

  .factory('$convert', ['$stringConverter', '$objectConverter', '$queryStringConverter', function ($stringConverter, $objectConverter, $queryStringConverter) {
    return {
      string: $stringConverter,
      object: $objectConverter,
      queryString: $queryStringConverter
    };
  }]);
})(window, window.angular);
(function (window, angular, undefined) {
  'use strict';


  angular.module('Convert.ObjectConverter.Service', ['Convert.StringConverter.Service'])

  .factory('$objectConverter', ['$stringConverter', function ($stringConverter) {

    function convertObjectKeys(obj, converter) {

      if (!angular.isObject(obj)) {
        throw new TypeError('Not an object');
      }

      if (typeof $stringConverter[converter] === 'undefined') {
        throw new Error('Invalid converter: ' + converter);
      }

      var newObj = {};

      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          var newKey = $stringConverter[converter](key);
          newObj[newKey] = angular.copy(obj[key]);
        }
      }

      return newObj;
    }

    return {

      keysToSnakeCase: function keysToSnakeCase(obj) {
        return convertObjectKeys(obj, 'toSnakeCase');
      },


      keysToCamelCase: function keysToCamelCase(obj) {
        return convertObjectKeys(obj, 'toCamelCase');
      }
    };
  }]);
})(window, window.angular);
(function (window, angular, undefined) {
  'use strict';


  angular.module('Convert.QueryStringConverter.Service', ['Convert.StringConverter.Service'])

  .factory('$queryStringConverter', ['$stringConverter', function ($stringConverter) {

    function tryDecodeURIComponent(str) {
      try {
        return decodeURIComponent(str);
      } catch (e) {
      }
    }

    function tryEncodeURIComponent(str, pctEncodeSpaces) {
      try {
        return encodeURIComponent(str).replace(/%40/g, '@').replace(/%3A/gi, ':').replace(/%24/g, '$').replace(/%2C/gi, ',').replace(/%20/g, pctEncodeSpaces ? '%20' : '+');
      } catch (e) {
      }
    }

    function convertToCase(str, convertCase) {
      switch (convertCase) {
        case 'snake':
          return $stringConverter.toSnakeCase(str);
        case 'camel':
          return $stringConverter.toCamelCase(str);
      }
      return str;
    }

    return {

      toObject: function toObject(s, convertCase) {
        var obj = {};
        var kv = void 0,
            key = void 0,
            val = void 0;
        angular.forEach((s || '').split('&'), function (s) {
          if (!s) {
            return;
          }

          kv = s.replace(/\+/g, '%20').split('=');
          key = tryDecodeURIComponent(kv[0]);

          if (!angular.isDefined(key)) {
            return;
          }

          key = convertToCase(key, convertCase);
          val = angular.isDefined(kv[1]) ? tryDecodeURIComponent(kv[1]) : true;

          if (!hasOwnProperty.call(obj, key)) {
            obj[key] = val;
          } else if (angular.isArray(obj[key])) {
            obj[key].push(val);
          } else {
            obj[key] = [obj[key], val];
          }
        });
        return obj;
      },


      fromObject: function fromObject(obj, convertCase) {

        if (!obj || !angular.isObject(obj)) {
          return '';
        }

        var parts = [];

        angular.forEach(obj, function (value, key) {

          if (value === null || angular.isUndefined(value)) {
            return;
          }

          if (!angular.isArray(value)) {
            value = [value];
          }

          angular.forEach(value, function (v) {

            if (angular.isObject(v)) {
              if (angular.isDate(v)) {
                v = v.toISOString();
              } else {
                v = angular.toJson(v);
              }
            }

            switch (convertCase) {
              case 'snake':
                key = $stringConverter.toSnakeCase(key);
                break;
              case 'camel':
                key = $stringConverter.toCamelCase(key);
                break;
            }

            parts.push(tryEncodeURIComponent(key) + '=' + tryEncodeURIComponent(v));
          });
        });

        if (parts.length > 0) {
          return parts.join('&');
        }

        return '';
      }
    };
  }]);
})(window, window.angular);
(function (window, angular, undefined) {
  'use strict';


  angular.module('Convert.StringConverter.Service', [])

  .factory('$stringConverter', ['$window', function ($window) {
    return {

      toSnakeCase: function toSnakeCase(str) {
        if (typeof str === 'number') {
          return String(str);
        } else if (typeof str !== 'string') {
          return '';
        }
        if ((str = String(str).trim()) === '') {
          return '';
        }
        return str.replace(/(\s*-*\b\w|[A-Z])/g, function ($1) {
          $1 = $1.trim().toLowerCase().replace('-', '');
          return ($1[0] === '_' ? '' : '_') + $1;
        }).slice(1);
      },


      toCamelCase: function toCamelCase(str, ucfirst) {
        if (typeof str === 'number') {
          return String(str);
        } else if (typeof str !== 'string') {
          return '';
        }
        if ((str = String(str).trim()) === '') {
          return '';
        }
        return str.replace(/_+|-+/g, ' ').replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
          if (Number(match) === 0) {
            return '';
          }
          return index === 0 && !ucfirst ? match.toLowerCase() : match.toUpperCase();
        });
      },


      toProperCase: function toProperCase(str) {
        if (typeof str === 'number') {
          return String(str);
        } else if (typeof str !== 'string') {
          return '';
        }
        if ((str = String(str).trim()) === '') {
          return '';
        }
        return str.replace(/\b\w+/g, function (match) {
          return match.charAt(0).toUpperCase() + match.substr(1).toLowerCase();
        }).replace(/['’”‘“][A-Z]{1}\b/g, function (match) {
          return match.toLowerCase();
        });
      },


      toDasherized: function toDasherized(str) {
        if (typeof str === 'number') {
          return String(str);
        } else if (typeof str !== 'string') {
          return '';
        }
        if ((str = String(str).trim()) === '') {
          return '';
        }
        str = str.replace(/\s\(/, '-(');
        return str.replace(/(\s*-*\b\w|[A-Z]|_[a-z])/g, function ($1) {
          $1 = $1.replace('_', '-').trim().toLowerCase();
          return ($1[0] === '-' ? '' : '-') + $1;
        }).slice(1).replace(/\(-/, '(');
      },


      toUcFirst: function toUcFirst(str) {
        if (typeof str !== 'string' && typeof str !== 'number') {
          return '';
        }
        str = String(str).trim();
        if (str === '') {
          return '';
        }
        return str[0].toUpperCase() + str.substr(1);
      },


      fromBase64: function fromBase64(str) {
        var o = str.replace('-', '+').replace('_', '/');
        switch (o.length % 4) {
          case 0:
            break;
          case 2:
            o += '==';
            break;
          case 3:
            o += '=';
            break;
          default:
            throw 'Illegal base64 url string';
        }
        try {
          return decodeURIComponent($window.atob(o));
        } catch (e) {
        }
      }
    };
  }]);
})(window, window.angular);