(function (window, angular, undefined) {
  'use strict';


  angular.module('Log.Service', [])

  .provider('$log', function $logProvider() {

    this.disabled = null;
    this.enabled = null;

    this.disable = function (methods) {
      if (typeof methods === 'string') {
        methods = [methods];
      }
      this.disabled = methods;
      this.enabled = null;
    };

    this.enable = function (methods) {
      if (typeof methods === 'string') {
        methods = [methods];
      }
      this.enabled = methods;
      this.disabled = null;
    };

    this.$get = ['$window', function ($window) {

      var enabled = this.enabled;
      var disabled = this.disabled;

      function isEnabled(method) {

        if (angular.isArray(enabled)) {
          if (enabled.indexOf('all') !== -1 || enabled.indexOf(method) !== -1) {
            return true;
          }
          return false;
        }

        if (angular.isArray(disabled)) {
          if (disabled.indexOf('all' !== -1 || disabled.indexOf(method) !== -1)) {
            return false;
          }
          return true;
        }

        return true;
      }

      function formatError(arg) {
        if (arg instanceof Error) {
          if (arg.stack) {
            arg = arg.message && arg.stack.indexOf(arg.message) === -1 ? 'Error: ' + arg.message + '\n' + arg.stack : arg.stack;
          } else if (arg.sourceURL) {
            arg = arg.message + '\n' + arg.sourceURL + ':' + arg.line;
          }
        }
        return arg;
      }

      function logger(method) {

        if (!isEnabled(method)) {
          return angular.noop;
        }

        var console = $window.console || {};
        var logFn = console[method] || console.log || angular.noop;
        var hasApply = false;

        try {
          hasApply = !!logFn.apply;
        } catch (e) {}


        if (hasApply) {
          return function () {
            for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
              args[_key] = arguments[_key];
            }

            args = args.map(formatError);
            return logFn.apply(console, args);
          };
        }

        return function (arg1, arg2) {
          logFn(arg1, arg2 === null ? '' : arg2);
        };
      }

      return {
        log: logger('log'),
        info: logger('info'),
        warn: logger('warn'),
        error: logger('error'),
        debug: logger('debug'),
        assert: logger('assert'),
        clear: logger('clear'),
        trace: logger('trace'),
        dir: logger('dir')
      };
    }];
  });
})(window, window.angular);