
var Sentry = (function (exports) {
    

    var extendStatics = function(d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };

    function __extends(d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    }

    var __assign = function() {
        __assign = Object.assign || function __assign(t) {
            for (var s, i = 1, n = arguments.length; i < n; i++) {
                s = arguments[i];
                for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
            }
            return t;
        };
        return __assign.apply(this, arguments);
    };

    function __rest(s, e) {
        var t = {};
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
            t[p] = s[p];
        if (s != null && typeof Object.getOwnPropertySymbols === "function")
            for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
                t[p[i]] = s[p[i]];
        return t;
    }

    function __values(o) {
        var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
        if (m) return m.call(o);
        return {
            next: function () {
                if (o && i >= o.length) o = void 0;
                return { value: o && o[i++], done: !o };
            }
        };
    }

    function __read(o, n) {
        var m = typeof Symbol === "function" && o[Symbol.iterator];
        if (!m) return o;
        var i = m.call(o), r, ar = [], e;
        try {
            while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
        }
        catch (error) { e = { error: error }; }
        finally {
            try {
                if (r && !r.done && (m = i["return"])) m.call(i);
            }
            finally { if (e) throw e.error; }
        }
        return ar;
    }

    function __spread() {
        for (var ar = [], i = 0; i < arguments.length; i++)
            ar = ar.concat(__read(arguments[i]));
        return ar;
    }

    
    var LogLevel;
    (function (LogLevel) {
        LogLevel[LogLevel["None"] = 0] = "None";
        LogLevel[LogLevel["Error"] = 1] = "Error";
        LogLevel[LogLevel["Debug"] = 2] = "Debug";
        LogLevel[LogLevel["Verbose"] = 3] = "Verbose";
    })(LogLevel || (LogLevel = {}));

    
    var SessionStatus;
    (function (SessionStatus) {
        SessionStatus["Ok"] = "ok";
        SessionStatus["Exited"] = "exited";
        SessionStatus["Crashed"] = "crashed";
        SessionStatus["Abnormal"] = "abnormal";
    })(SessionStatus || (SessionStatus = {}));
    var RequestSessionStatus;
    (function (RequestSessionStatus) {
        RequestSessionStatus["Ok"] = "ok";
        RequestSessionStatus["Errored"] = "errored";
        RequestSessionStatus["Crashed"] = "crashed";
    })(RequestSessionStatus || (RequestSessionStatus = {}));

    
    (function (Severity) {
        Severity["Fatal"] = "fatal";
        Severity["Error"] = "error";
        Severity["Warning"] = "warning";
        Severity["Log"] = "log";
        Severity["Info"] = "info";
        Severity["Debug"] = "debug";
        Severity["Critical"] = "critical";
    })(exports.Severity || (exports.Severity = {}));
    (function (Severity) {
        function fromString(level) {
            switch (level) {
                case 'debug':
                    return Severity.Debug;
                case 'info':
                    return Severity.Info;
                case 'warn':
                case 'warning':
                    return Severity.Warning;
                case 'error':
                    return Severity.Error;
                case 'fatal':
                    return Severity.Fatal;
                case 'critical':
                    return Severity.Critical;
                case 'log':
                default:
                    return Severity.Log;
            }
        }
        Severity.fromString = fromString;
    })(exports.Severity || (exports.Severity = {}));

    
    (function (Status) {
        Status["Unknown"] = "unknown";
        Status["Skipped"] = "skipped";
        Status["Success"] = "success";
        Status["RateLimit"] = "rate_limit";
        Status["Invalid"] = "invalid";
        Status["Failed"] = "failed";
    })(exports.Status || (exports.Status = {}));
    (function (Status) {
        function fromHttpCode(code) {
            if (code >= 200 && code < 300) {
                return Status.Success;
            }
            if (code === 429) {
                return Status.RateLimit;
            }
            if (code >= 400 && code < 500) {
                return Status.Invalid;
            }
            if (code >= 500) {
                return Status.Failed;
            }
            return Status.Unknown;
        }
        Status.fromHttpCode = fromHttpCode;
    })(exports.Status || (exports.Status = {}));

    var TransactionSamplingMethod;
    (function (TransactionSamplingMethod) {
        TransactionSamplingMethod["Explicit"] = "explicitly_set";
        TransactionSamplingMethod["Sampler"] = "client_sampler";
        TransactionSamplingMethod["Rate"] = "client_rate";
        TransactionSamplingMethod["Inheritance"] = "inheritance";
    })(TransactionSamplingMethod || (TransactionSamplingMethod = {}));

    var Outcome;
    (function (Outcome) {
        Outcome["BeforeSend"] = "before_send";
        Outcome["EventProcessor"] = "event_processor";
        Outcome["NetworkError"] = "network_error";
        Outcome["QueueOverflow"] = "queue_overflow";
        Outcome["RateLimitBackoff"] = "ratelimit_backoff";
        Outcome["SampleRate"] = "sample_rate";
    })(Outcome || (Outcome = {}));

    
    function forget(promise) {
        void promise.then(null, function (e) {
            console.error(e);
        });
    }

    
    function isNodeEnv() {
        return Object.prototype.toString.call(typeof process !== 'undefined' ? process : 0) === '[object process]';
    }
    function dynamicRequire(mod, request) {
        return mod.require(request);
    }

    
    var fallbackGlobalObject = {};
    function getGlobalObject() {
        return (isNodeEnv()
            ? global
            : typeof window !== 'undefined' 
                ? window 
                : typeof self !== 'undefined'
                    ? self
                    : fallbackGlobalObject);
    }

    
    function isError(wat) {
        switch (Object.prototype.toString.call(wat)) {
            case '[object Error]':
                return true;
            case '[object Exception]':
                return true;
            case '[object DOMException]':
                return true;
            default:
                return isInstanceOf(wat, Error);
        }
    }
    function isErrorEvent(wat) {
        return Object.prototype.toString.call(wat) === '[object ErrorEvent]';
    }
    function isDOMError(wat) {
        return Object.prototype.toString.call(wat) === '[object DOMError]';
    }
    function isDOMException(wat) {
        return Object.prototype.toString.call(wat) === '[object DOMException]';
    }
    function isString(wat) {
        return Object.prototype.toString.call(wat) === '[object String]';
    }
    function isPrimitive(wat) {
        return wat === null || (typeof wat !== 'object' && typeof wat !== 'function');
    }
    function isPlainObject(wat) {
        return Object.prototype.toString.call(wat) === '[object Object]';
    }
    function isEvent(wat) {
        return typeof Event !== 'undefined' && isInstanceOf(wat, Event);
    }
    function isElement(wat) {
        return typeof Element !== 'undefined' && isInstanceOf(wat, Element);
    }
    function isRegExp(wat) {
        return Object.prototype.toString.call(wat) === '[object RegExp]';
    }
    function isThenable(wat) {
        return Boolean(wat && wat.then && typeof wat.then === 'function');
    }
    function isSyntheticEvent(wat) {
        return isPlainObject(wat) && 'nativeEvent' in wat && 'preventDefault' in wat && 'stopPropagation' in wat;
    }
    function isInstanceOf(wat, base) {
        try {
            return wat instanceof base;
        }
        catch (_e) {
            return false;
        }
    }

    
    function htmlTreeAsString(elem, keyAttrs) {
        try {
            var currentElem = elem;
            var MAX_TRAVERSE_HEIGHT = 5;
            var MAX_OUTPUT_LEN = 80;
            var out = [];
            var height = 0;
            var len = 0;
            var separator = ' > ';
            var sepLength = separator.length;
            var nextStr = void 0;
            while (currentElem && height++ < MAX_TRAVERSE_HEIGHT) {
                nextStr = _htmlElementAsString(currentElem, keyAttrs);
                if (nextStr === 'html' || (height > 1 && len + out.length * sepLength + nextStr.length >= MAX_OUTPUT_LEN)) {
                    break;
                }
                out.push(nextStr);
                len += nextStr.length;
                currentElem = currentElem.parentNode;
            }
            return out.reverse().join(separator);
        }
        catch (_oO) {
            return '<unknown>';
        }
    }
    function _htmlElementAsString(el, keyAttrs) {
        var _a, _b;
        var elem = el;
        var out = [];
        var className;
        var classes;
        var key;
        var attr;
        var i;
        if (!elem || !elem.tagName) {
            return '';
        }
        out.push(elem.tagName.toLowerCase());
        var keyAttrPairs = ((_a = keyAttrs) === null || _a === void 0 ? void 0 : _a.length) ? keyAttrs.filter(function (keyAttr) { return elem.getAttribute(keyAttr); }).map(function (keyAttr) { return [keyAttr, elem.getAttribute(keyAttr)]; })
            : null;
        if ((_b = keyAttrPairs) === null || _b === void 0 ? void 0 : _b.length) {
            keyAttrPairs.forEach(function (keyAttrPair) {
                out.push("[" + keyAttrPair[0] + "=\"" + keyAttrPair[1] + "\"]");
            });
        }
        else {
            if (elem.id) {
                out.push("#" + elem.id);
            }
            className = elem.className;
            if (className && isString(className)) {
                classes = className.split(/\s+/);
                for (i = 0; i < classes.length; i++) {
                    out.push("." + classes[i]);
                }
            }
        }
        var allowedAttrs = ['type', 'name', 'title', 'alt'];
        for (i = 0; i < allowedAttrs.length; i++) {
            key = allowedAttrs[i];
            attr = elem.getAttribute(key);
            if (attr) {
                out.push("[" + key + "=\"" + attr + "\"]");
            }
        }
        return out.join('');
    }
    function getLocationHref() {
        var global = getGlobalObject();
        try {
            return global.document.location.href;
        }
        catch (oO) {
            return '';
        }
    }

    var setPrototypeOf = Object.setPrototypeOf || ({ __proto__: [] } instanceof Array ? setProtoOf : mixinProperties);
    function setProtoOf(obj, proto) {
        obj.__proto__ = proto;
        return obj;
    }
    function mixinProperties(obj, proto) {
        for (var prop in proto) {
            if (!obj.hasOwnProperty(prop)) {
                obj[prop] = proto[prop];
            }
        }
        return obj;
    }

    
    var SentryError =  (function (_super) {
        __extends(SentryError, _super);
        function SentryError(message) {
            var _newTarget = this.constructor;
            var _this = _super.call(this, message) || this;
            _this.message = message;
            _this.name = _newTarget.prototype.constructor.name;
            setPrototypeOf(_this, _newTarget.prototype);
            return _this;
        }
        return SentryError;
    }(Error));

    
    var DSN_REGEX = /^(?:(\w+):)\/\/(?:(\w+)(?::(\w+))?@)([\w.-]+)(?::(\d+))?\/(.+)/;
    var ERROR_MESSAGE = 'Invalid Dsn';
    var Dsn =  (function () {
        function Dsn(from) {
            if (typeof from === 'string') {
                this._fromString(from);
            }
            else {
                this._fromComponents(from);
            }
            this._validate();
        }
        Dsn.prototype.toString = function (withPassword) {
            if (withPassword === void 0) { withPassword = false; }
            var _a = this, host = _a.host, path = _a.path, pass = _a.pass, port = _a.port, projectId = _a.projectId, protocol = _a.protocol, publicKey = _a.publicKey;
            return (protocol + "://" + publicKey + (withPassword && pass ? ":" + pass : '') +
                ("@" + host + (port ? ":" + port : '') + "/" + (path ? path + "/" : path) + projectId));
        };
        Dsn.prototype._fromString = function (str) {
            var match = DSN_REGEX.exec(str);
            if (!match) {
                throw new SentryError(ERROR_MESSAGE);
            }
            var _a = __read(match.slice(1), 6), protocol = _a[0], publicKey = _a[1], _b = _a[2], pass = _b === void 0 ? '' : _b, host = _a[3], _c = _a[4], port = _c === void 0 ? '' : _c, lastPath = _a[5];
            var path = '';
            var projectId = lastPath;
            var split = projectId.split('/');
            if (split.length > 1) {
                path = split.slice(0, -1).join('/');
                projectId = split.pop();
            }
            if (projectId) {
                var projectMatch = projectId.match(/^\d+/);
                if (projectMatch) {
                    projectId = projectMatch[0];
                }
            }
            this._fromComponents({ host: host, pass: pass, path: path, projectId: projectId, port: port, protocol: protocol, publicKey: publicKey });
        };
        Dsn.prototype._fromComponents = function (components) {
            if ('user' in components && !('publicKey' in components)) {
                components.publicKey = components.user;
            }
            this.user = components.publicKey || '';
            this.protocol = components.protocol;
            this.publicKey = components.publicKey || '';
            this.pass = components.pass || '';
            this.host = components.host;
            this.port = components.port || '';
            this.path = components.path || '';
            this.projectId = components.projectId;
        };
        Dsn.prototype._validate = function () {
            var _this = this;
            ['protocol', 'publicKey', 'host', 'projectId'].forEach(function (component) {
                if (!_this[component]) {
                    throw new SentryError(ERROR_MESSAGE + ": " + component + " missing");
                }
            });
            if (!this.projectId.match(/^\d+$/)) {
                throw new SentryError(ERROR_MESSAGE + ": Invalid projectId " + this.projectId);
            }
            if (this.protocol !== 'http' && this.protocol !== 'https') {
                throw new SentryError(ERROR_MESSAGE + ": Invalid protocol " + this.protocol);
            }
            if (this.port && isNaN(parseInt(this.port, 10))) {
                throw new SentryError(ERROR_MESSAGE + ": Invalid port " + this.port);
            }
        };
        return Dsn;
    }());

    
    var global$1 = getGlobalObject();
    var PREFIX = 'Sentry Logger ';
    function consoleSandbox(callback) {
        var global = getGlobalObject();
        var levels = ['debug', 'info', 'warn', 'error', 'log', 'assert'];
        if (!('console' in global)) {
            return callback();
        }
        var originalConsole = global.console;
        var wrappedLevels = {};
        levels.forEach(function (level) {
            if (level in global.console && originalConsole[level].__sentry_original__) {
                wrappedLevels[level] = originalConsole[level];
                originalConsole[level] = originalConsole[level].__sentry_original__;
            }
        });
        var result = callback();
        Object.keys(wrappedLevels).forEach(function (level) {
            originalConsole[level] = wrappedLevels[level];
        });
        return result;
    }
    var Logger =  (function () {
        function Logger() {
            this._enabled = false;
        }
        Logger.prototype.disable = function () {
            this._enabled = false;
        };
        Logger.prototype.enable = function () {
            this._enabled = true;
        };
        Logger.prototype.log = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (!this._enabled) {
                return;
            }
            consoleSandbox(function () {
                global$1.console.log(PREFIX + "[Log]: " + args.join(' '));
            });
        };
        Logger.prototype.warn = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (!this._enabled) {
                return;
            }
            consoleSandbox(function () {
                global$1.console.warn(PREFIX + "[Warn]: " + args.join(' '));
            });
        };
        Logger.prototype.error = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (!this._enabled) {
                return;
            }
            consoleSandbox(function () {
                global$1.console.error(PREFIX + "[Error]: " + args.join(' '));
            });
        };
        return Logger;
    }());
    global$1.__SENTRY__ = global$1.__SENTRY__ || {};
    var logger = global$1.__SENTRY__.logger || (global$1.__SENTRY__.logger = new Logger());

    
    var Memo =  (function () {
        function Memo() {
            this._hasWeakSet = typeof WeakSet === 'function';
            this._inner = this._hasWeakSet ? new WeakSet() : [];
        }
        Memo.prototype.memoize = function (obj) {
            if (this._hasWeakSet) {
                if (this._inner.has(obj)) {
                    return true;
                }
                this._inner.add(obj);
                return false;
            }
            for (var i = 0; i < this._inner.length; i++) {
                var value = this._inner[i];
                if (value === obj) {
                    return true;
                }
            }
            this._inner.push(obj);
            return false;
        };
        Memo.prototype.unmemoize = function (obj) {
            if (this._hasWeakSet) {
                this._inner.delete(obj);
            }
            else {
                for (var i = 0; i < this._inner.length; i++) {
                    if (this._inner[i] === obj) {
                        this._inner.splice(i, 1);
                        break;
                    }
                }
            }
        };
        return Memo;
    }());

    var defaultFunctionName = '<anonymous>';
    function getFunctionName(fn) {
        try {
            if (!fn || typeof fn !== 'function') {
                return defaultFunctionName;
            }
            return fn.name || defaultFunctionName;
        }
        catch (e) {
            return defaultFunctionName;
        }
    }

    
    function truncate(str, max) {
        if (max === void 0) { max = 0; }
        if (typeof str !== 'string' || max === 0) {
            return str;
        }
        return str.length <= max ? str : str.substr(0, max) + "...";
    }
    function safeJoin(input, delimiter) {
        if (!Array.isArray(input)) {
            return '';
        }
        var output = [];
        for (var i = 0; i < input.length; i++) {
            var value = input[i];
            try {
                output.push(String(value));
            }
            catch (e) {
                output.push('[value cannot be serialized]');
            }
        }
        return output.join(delimiter);
    }
    function isMatchingPattern(value, pattern) {
        if (!isString(value)) {
            return false;
        }
        if (isRegExp(pattern)) {
            return pattern.test(value);
        }
        if (typeof pattern === 'string') {
            return value.indexOf(pattern) !== -1;
        }
        return false;
    }

    
    function fill(source, name, replacementFactory) {
        if (!(name in source)) {
            return;
        }
        var original = source[name];
        var wrapped = replacementFactory(original);
        if (typeof wrapped === 'function') {
            try {
                wrapped.prototype = wrapped.prototype || {};
                Object.defineProperties(wrapped, {
                    __sentry_original__: {
                        enumerable: false,
                        value: original,
                    },
                });
            }
            catch (_Oo) {
            }
        }
        source[name] = wrapped;
    }
    function urlEncode(object) {
        return Object.keys(object)
            .map(function (key) { return encodeURIComponent(key) + "=" + encodeURIComponent(object[key]); })
            .join('&');
    }
    function getWalkSource(value) {
        if (isError(value)) {
            var error = value;
            var err = {
                message: error.message,
                name: error.name,
                stack: error.stack,
            };
            for (var i in error) {
                if (Object.prototype.hasOwnProperty.call(error, i)) {
                    err[i] = error[i];
                }
            }
            return err;
        }
        if (isEvent(value)) {
            var event_1 = value;
            var source = {};
            source.type = event_1.type;
            try {
                source.target = isElement(event_1.target)
                    ? htmlTreeAsString(event_1.target)
                    : Object.prototype.toString.call(event_1.target);
            }
            catch (_oO) {
                source.target = '<unknown>';
            }
            try {
                source.currentTarget = isElement(event_1.currentTarget)
                    ? htmlTreeAsString(event_1.currentTarget)
                    : Object.prototype.toString.call(event_1.currentTarget);
            }
            catch (_oO) {
                source.currentTarget = '<unknown>';
            }
            if (typeof CustomEvent !== 'undefined' && isInstanceOf(value, CustomEvent)) {
                source.detail = event_1.detail;
            }
            for (var attr in event_1) {
                if (Object.prototype.hasOwnProperty.call(event_1, attr)) {
                    source[attr] = event_1[attr];
                }
            }
            return source;
        }
        return value;
    }
    function utf8Length(value) {
        return ~-encodeURI(value).split(/%..|./).length;
    }
    function jsonSize(value) {
        return utf8Length(JSON.stringify(value));
    }
    function normalizeToSize(object, 
    depth, 
    maxSize) {
        if (depth === void 0) { depth = 3; }
        if (maxSize === void 0) { maxSize = 100 * 1024; }
        var serialized = normalize(object, depth);
        if (jsonSize(serialized) > maxSize) {
            return normalizeToSize(object, depth - 1, maxSize);
        }
        return serialized;
    }
    function serializeValue(value) {
        var type = Object.prototype.toString.call(value);
        if (typeof value === 'string') {
            return value;
        }
        if (type === '[object Object]') {
            return '[Object]';
        }
        if (type === '[object Array]') {
            return '[Array]';
        }
        var normalized = normalizeValue(value);
        return isPrimitive(normalized) ? normalized : type;
    }
    function normalizeValue(value, key) {
        if (key === 'domain' && value && typeof value === 'object' && value._events) {
            return '[Domain]';
        }
        if (key === 'domainEmitter') {
            return '[DomainEmitter]';
        }
        if (typeof global !== 'undefined' && value === global) {
            return '[Global]';
        }
        if (typeof window !== 'undefined' && value === window) {
            return '[Window]';
        }
        if (typeof document !== 'undefined' && value === document) {
            return '[Document]';
        }
        if (isSyntheticEvent(value)) {
            return '[SyntheticEvent]';
        }
        if (typeof value === 'number' && value !== value) {
            return '[NaN]';
        }
        if (value === void 0) {
            return '[undefined]';
        }
        if (typeof value === 'function') {
            return "[Function: " + getFunctionName(value) + "]";
        }
        if (typeof value === 'symbol') {
            return "[" + String(value) + "]";
        }
        if (typeof value === 'bigint') {
            return "[BigInt: " + String(value) + "]";
        }
        return value;
    }
    function walk(key, value, depth, memo) {
        if (depth === void 0) { depth = +Infinity; }
        if (memo === void 0) { memo = new Memo(); }
        if (depth === 0) {
            return serializeValue(value);
        }
        if (value !== null && value !== undefined && typeof value.toJSON === 'function') {
            return value.toJSON();
        }
        var normalized = normalizeValue(value, key);
        if (isPrimitive(normalized)) {
            return normalized;
        }
        var source = getWalkSource(value);
        var acc = Array.isArray(value) ? [] : {};
        if (memo.memoize(value)) {
            return '[Circular ~]';
        }
        for (var innerKey in source) {
            if (!Object.prototype.hasOwnProperty.call(source, innerKey)) {
                continue;
            }
            acc[innerKey] = walk(innerKey, source[innerKey], depth - 1, memo);
        }
        memo.unmemoize(value);
        return acc;
    }
    function normalize(input, depth) {
        try {
            return JSON.parse(JSON.stringify(input, function (key, value) { return walk(key, value, depth); }));
        }
        catch (_oO) {
            return '**non-serializable**';
        }
    }
    function extractExceptionKeysForMessage(exception, maxLength) {
        if (maxLength === void 0) { maxLength = 40; }
        var keys = Object.keys(getWalkSource(exception));
        keys.sort();
        if (!keys.length) {
            return '[object has no keys]';
        }
        if (keys[0].length >= maxLength) {
            return truncate(keys[0], maxLength);
        }
        for (var includedKeys = keys.length; includedKeys > 0; includedKeys--) {
            var serialized = keys.slice(0, includedKeys).join(', ');
            if (serialized.length > maxLength) {
                continue;
            }
            if (includedKeys === keys.length) {
                return serialized;
            }
            return truncate(serialized, maxLength);
        }
        return '';
    }
    function dropUndefinedKeys(val) {
        var e_1, _a;
        if (isPlainObject(val)) {
            var obj = val;
            var rv = {};
            try {
                for (var _b = __values(Object.keys(obj)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var key = _c.value;
                    if (typeof obj[key] !== 'undefined') {
                        rv[key] = dropUndefinedKeys(obj[key]);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return rv;
        }
        if (Array.isArray(val)) {
            return val.map(dropUndefinedKeys);
        }
        return val;
    }

    
    function supportsFetch() {
        if (!('fetch' in getGlobalObject())) {
            return false;
        }
        try {
            new Headers();
            new Request('');
            new Response();
            return true;
        }
        catch (e) {
            return false;
        }
    }
    function isNativeFetch(func) {
        return func && /^function fetch\(\)\s+\{\s+\[native code\]\s+\}$/.test(func.toString());
    }
    function supportsNativeFetch() {
        if (!supportsFetch()) {
            return false;
        }
        var global = getGlobalObject();
        if (isNativeFetch(global.fetch)) {
            return true;
        }
        var result = false;
        var doc = global.document;
        if (doc && typeof doc.createElement === "function") {
            try {
                var sandbox = doc.createElement('iframe');
                sandbox.hidden = true;
                doc.head.appendChild(sandbox);
                if (sandbox.contentWindow && sandbox.contentWindow.fetch) {
                    result = isNativeFetch(sandbox.contentWindow.fetch);
                }
                doc.head.removeChild(sandbox);
            }
            catch (err) {
                logger.warn('Could not create sandbox iframe for pure fetch check, bailing to window.fetch: ', err);
            }
        }
        return result;
    }
    function supportsReferrerPolicy() {
        if (!supportsFetch()) {
            return false;
        }
        try {
            new Request('_', {
                referrerPolicy: 'origin',
            });
            return true;
        }
        catch (e) {
            return false;
        }
    }
    function supportsHistory() {
        var global = getGlobalObject();
        var chrome = global.chrome;
        var isChromePackagedApp = chrome && chrome.app && chrome.app.runtime;
        var hasHistoryApi = 'history' in global && !!global.history.pushState && !!global.history.replaceState;
        return !isChromePackagedApp && hasHistoryApi;
    }

    var global$2 = getGlobalObject();
    var handlers = {};
    var instrumented = {};
    function instrument(type) {
        if (instrumented[type]) {
            return;
        }
        instrumented[type] = true;
        switch (type) {
            case 'console':
                instrumentConsole();
                break;
            case 'dom':
                instrumentDOM();
                break;
            case 'xhr':
                instrumentXHR();
                break;
            case 'fetch':
                instrumentFetch();
                break;
            case 'history':
                instrumentHistory();
                break;
            case 'error':
                instrumentError();
                break;
            case 'unhandledrejection':
                instrumentUnhandledRejection();
                break;
            default:
                logger.warn('unknown instrumentation type:', type);
        }
    }
    function addInstrumentationHandler(handler) {
        if (!handler || typeof handler.type !== 'string' || typeof handler.callback !== 'function') {
            return;
        }
        handlers[handler.type] = handlers[handler.type] || [];
        handlers[handler.type].push(handler.callback);
        instrument(handler.type);
    }
    function triggerHandlers(type, data) {
        var e_1, _a;
        if (!type || !handlers[type]) {
            return;
        }
        try {
            for (var _b = __values(handlers[type] || []), _c = _b.next(); !_c.done; _c = _b.next()) {
                var handler = _c.value;
                try {
                    handler(data);
                }
                catch (e) {
                    logger.error("Error while triggering instrumentation handler.\nType: " + type + "\nName: " + getFunctionName(handler) + "\nError: " + e);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    function instrumentConsole() {
        if (!('console' in global$2)) {
            return;
        }
        ['debug', 'info', 'warn', 'error', 'log', 'assert'].forEach(function (level) {
            if (!(level in global$2.console)) {
                return;
            }
            fill(global$2.console, level, function (originalConsoleLevel) {
                return function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    triggerHandlers('console', { args: args, level: level });
                    if (originalConsoleLevel) {
                        Function.prototype.apply.call(originalConsoleLevel, global$2.console, args);
                    }
                };
            });
        });
    }
    function instrumentFetch() {
        if (!supportsNativeFetch()) {
            return;
        }
        fill(global$2, 'fetch', function (originalFetch) {
            return function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var handlerData = {
                    args: args,
                    fetchData: {
                        method: getFetchMethod(args),
                        url: getFetchUrl(args),
                    },
                    startTimestamp: Date.now(),
                };
                triggerHandlers('fetch', __assign({}, handlerData));
                return originalFetch.apply(global$2, args).then(function (response) {
                    triggerHandlers('fetch', __assign(__assign({}, handlerData), { endTimestamp: Date.now(), response: response }));
                    return response;
                }, function (error) {
                    triggerHandlers('fetch', __assign(__assign({}, handlerData), { endTimestamp: Date.now(), error: error }));
                    throw error;
                });
            };
        });
    }
    function getFetchMethod(fetchArgs) {
        if (fetchArgs === void 0) { fetchArgs = []; }
        if ('Request' in global$2 && isInstanceOf(fetchArgs[0], Request) && fetchArgs[0].method) {
            return String(fetchArgs[0].method).toUpperCase();
        }
        if (fetchArgs[1] && fetchArgs[1].method) {
            return String(fetchArgs[1].method).toUpperCase();
        }
        return 'GET';
    }
    function getFetchUrl(fetchArgs) {
        if (fetchArgs === void 0) { fetchArgs = []; }
        if (typeof fetchArgs[0] === 'string') {
            return fetchArgs[0];
        }
        if ('Request' in global$2 && isInstanceOf(fetchArgs[0], Request)) {
            return fetchArgs[0].url;
        }
        return String(fetchArgs[0]);
    }
    function instrumentXHR() {
        if (!('XMLHttpRequest' in global$2)) {
            return;
        }
        var requestKeys = [];
        var requestValues = [];
        var xhrproto = XMLHttpRequest.prototype;
        fill(xhrproto, 'open', function (originalOpen) {
            return function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var xhr = this;
                var url = args[1];
                xhr.__sentry_xhr__ = {
                    method: isString(args[0]) ? args[0].toUpperCase() : args[0],
                    url: args[1],
                };
                if (isString(url) && xhr.__sentry_xhr__.method === 'POST' && url.match(/sentry_key/)) {
                    xhr.__sentry_own_request__ = true;
                }
                var onreadystatechangeHandler = function () {
                    if (xhr.readyState === 4) {
                        try {
                            if (xhr.__sentry_xhr__) {
                                xhr.__sentry_xhr__.status_code = xhr.status;
                            }
                        }
                        catch (e) {
                        }
                        try {
                            var requestPos = requestKeys.indexOf(xhr);
                            if (requestPos !== -1) {
                                requestKeys.splice(requestPos);
                                var args_1 = requestValues.splice(requestPos)[0];
                                if (xhr.__sentry_xhr__ && args_1[0] !== undefined) {
                                    xhr.__sentry_xhr__.body = args_1[0];
                                }
                            }
                        }
                        catch (e) {
                        }
                        triggerHandlers('xhr', {
                            args: args,
                            endTimestamp: Date.now(),
                            startTimestamp: Date.now(),
                            xhr: xhr,
                        });
                    }
                };
                if ('onreadystatechange' in xhr && typeof xhr.onreadystatechange === 'function') {
                    fill(xhr, 'onreadystatechange', function (original) {
                        return function () {
                            var readyStateArgs = [];
                            for (var _i = 0; _i < arguments.length; _i++) {
                                readyStateArgs[_i] = arguments[_i];
                            }
                            onreadystatechangeHandler();
                            return original.apply(xhr, readyStateArgs);
                        };
                    });
                }
                else {
                    xhr.addEventListener('readystatechange', onreadystatechangeHandler);
                }
                return originalOpen.apply(xhr, args);
            };
        });
        fill(xhrproto, 'send', function (originalSend) {
            return function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                requestKeys.push(this);
                requestValues.push(args);
                triggerHandlers('xhr', {
                    args: args,
                    startTimestamp: Date.now(),
                    xhr: this,
                });
                return originalSend.apply(this, args);
            };
        });
    }
    var lastHref;
    function instrumentHistory() {
        if (!supportsHistory()) {
            return;
        }
        var oldOnPopState = global$2.onpopstate;
        global$2.onpopstate = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var to = global$2.location.href;
            var from = lastHref;
            lastHref = to;
            triggerHandlers('history', {
                from: from,
                to: to,
            });
            if (oldOnPopState) {
                try {
                    return oldOnPopState.apply(this, args);
                }
                catch (_oO) {
                }
            }
        };
        function historyReplacementFunction(originalHistoryFunction) {
            return function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var url = args.length > 2 ? args[2] : undefined;
                if (url) {
                    var from = lastHref;
                    var to = String(url);
                    lastHref = to;
                    triggerHandlers('history', {
                        from: from,
                        to: to,
                    });
                }
                return originalHistoryFunction.apply(this, args);
            };
        }
        fill(global$2.history, 'pushState', historyReplacementFunction);
        fill(global$2.history, 'replaceState', historyReplacementFunction);
    }
    var debounceDuration = 1000;
    var debounceTimerID;
    var lastCapturedEvent;
    function shouldShortcircuitPreviousDebounce(previous, current) {
        if (!previous) {
            return true;
        }
        if (previous.type !== current.type) {
            return true;
        }
        try {
            if (previous.target !== current.target) {
                return true;
            }
        }
        catch (e) {
        }
        return false;
    }
    function shouldSkipDOMEvent(event) {
        if (event.type !== 'keypress') {
            return false;
        }
        try {
            var target = event.target;
            if (!target || !target.tagName) {
                return true;
            }
            if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || target.isContentEditable) {
                return false;
            }
        }
        catch (e) {
        }
        return true;
    }
    function makeDOMEventHandler(handler, globalListener) {
        if (globalListener === void 0) { globalListener = false; }
        return function (event) {
            if (!event || lastCapturedEvent === event) {
                return;
            }
            if (shouldSkipDOMEvent(event)) {
                return;
            }
            var name = event.type === 'keypress' ? 'input' : event.type;
            if (debounceTimerID === undefined) {
                handler({
                    event: event,
                    name: name,
                    global: globalListener,
                });
                lastCapturedEvent = event;
            }
            else if (shouldShortcircuitPreviousDebounce(lastCapturedEvent, event)) {
                handler({
                    event: event,
                    name: name,
                    global: globalListener,
                });
                lastCapturedEvent = event;
            }
            clearTimeout(debounceTimerID);
            debounceTimerID = global$2.setTimeout(function () {
                debounceTimerID = undefined;
            }, debounceDuration);
        };
    }
    function instrumentDOM() {
        if (!('document' in global$2)) {
            return;
        }
        var triggerDOMHandler = triggerHandlers.bind(null, 'dom');
        var globalDOMEventHandler = makeDOMEventHandler(triggerDOMHandler, true);
        global$2.document.addEventListener('click', globalDOMEventHandler, false);
        global$2.document.addEventListener('keypress', globalDOMEventHandler, false);
        ['EventTarget', 'Node'].forEach(function (target) {
            var proto = global$2[target] && global$2[target].prototype;
            if (!proto || !proto.hasOwnProperty || !proto.hasOwnProperty('addEventListener')) {
                return;
            }
            fill(proto, 'addEventListener', function (originalAddEventListener) {
                return function (type, listener, options) {
                    if (type === 'click' || type == 'keypress') {
                        try {
                            var el = this;
                            var handlers_1 = (el.__sentry_instrumentation_handlers__ = el.__sentry_instrumentation_handlers__ || {});
                            var handlerForType = (handlers_1[type] = handlers_1[type] || { refCount: 0 });
                            if (!handlerForType.handler) {
                                var handler = makeDOMEventHandler(triggerDOMHandler);
                                handlerForType.handler = handler;
                                originalAddEventListener.call(this, type, handler, options);
                            }
                            handlerForType.refCount += 1;
                        }
                        catch (e) {
                        }
                    }
                    return originalAddEventListener.call(this, type, listener, options);
                };
            });
            fill(proto, 'removeEventListener', function (originalRemoveEventListener) {
                return function (type, listener, options) {
                    if (type === 'click' || type == 'keypress') {
                        try {
                            var el = this;
                            var handlers_2 = el.__sentry_instrumentation_handlers__ || {};
                            var handlerForType = handlers_2[type];
                            if (handlerForType) {
                                handlerForType.refCount -= 1;
                                if (handlerForType.refCount <= 0) {
                                    originalRemoveEventListener.call(this, type, handlerForType.handler, options);
                                    handlerForType.handler = undefined;
                                    delete handlers_2[type]; 
                                }
                                if (Object.keys(handlers_2).length === 0) {
                                    delete el.__sentry_instrumentation_handlers__;
                                }
                            }
                        }
                        catch (e) {
                        }
                    }
                    return originalRemoveEventListener.call(this, type, listener, options);
                };
            });
        });
    }
    var _oldOnErrorHandler = null;
    function instrumentError() {
        _oldOnErrorHandler = global$2.onerror;
        global$2.onerror = function (msg, url, line, column, error) {
            triggerHandlers('error', {
                column: column,
                error: error,
                line: line,
                msg: msg,
                url: url,
            });
            if (_oldOnErrorHandler) {
                return _oldOnErrorHandler.apply(this, arguments);
            }
            return false;
        };
    }
    var _oldOnUnhandledRejectionHandler = null;
    function instrumentUnhandledRejection() {
        _oldOnUnhandledRejectionHandler = global$2.onunhandledrejection;
        global$2.onunhandledrejection = function (e) {
            triggerHandlers('unhandledrejection', e);
            if (_oldOnUnhandledRejectionHandler) {
                return _oldOnUnhandledRejectionHandler.apply(this, arguments);
            }
            return true;
        };
    }

    
    function uuid4() {
        var global = getGlobalObject();
        var crypto = global.crypto || global.msCrypto;
        if (!(crypto === void 0) && crypto.getRandomValues) {
            var arr = new Uint16Array(8);
            crypto.getRandomValues(arr);
            arr[3] = (arr[3] & 0xfff) | 0x4000;
            arr[4] = (arr[4] & 0x3fff) | 0x8000;
            var pad = function (num) {
                var v = num.toString(16);
                while (v.length < 4) {
                    v = "0" + v;
                }
                return v;
            };
            return (pad(arr[0]) + pad(arr[1]) + pad(arr[2]) + pad(arr[3]) + pad(arr[4]) + pad(arr[5]) + pad(arr[6]) + pad(arr[7]));
        }
        return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0;
            var v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
    function parseUrl(url) {
        if (!url) {
            return {};
        }
        var match = url.match(/^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?$/);
        if (!match) {
            return {};
        }
        var query = match[6] || '';
        var fragment = match[8] || '';
        return {
            host: match[4],
            path: match[5],
            protocol: match[2],
            relative: match[5] + query + fragment,
        };
    }
    function getEventDescription(event) {
        if (event.message) {
            return event.message;
        }
        if (event.exception && event.exception.values && event.exception.values[0]) {
            var exception = event.exception.values[0];
            if (exception.type && exception.value) {
                return exception.type + ": " + exception.value;
            }
            return exception.type || exception.value || event.event_id || '<unknown>';
        }
        return event.event_id || '<unknown>';
    }
    function addExceptionTypeValue(event, value, type) {
        event.exception = event.exception || {};
        event.exception.values = event.exception.values || [];
        event.exception.values[0] = event.exception.values[0] || {};
        event.exception.values[0].value = event.exception.values[0].value || value || '';
        event.exception.values[0].type = event.exception.values[0].type || type || 'Error';
    }
    function addExceptionMechanism(event, newMechanism) {
        var _a;
        if (!event.exception || !event.exception.values) {
            return;
        }
        var exceptionValue0 = event.exception.values[0];
        var defaultMechanism = { type: 'generic', handled: true };
        var currentMechanism = exceptionValue0.mechanism;
        exceptionValue0.mechanism = __assign(__assign(__assign({}, defaultMechanism), currentMechanism), newMechanism);
        if (newMechanism && 'data' in newMechanism) {
            var mergedData = __assign(__assign({}, (_a = currentMechanism) === null || _a === void 0 ? void 0 : _a.data), newMechanism.data);
            exceptionValue0.mechanism.data = mergedData;
        }
    }
    var defaultRetryAfter = 60 * 1000; 
    function parseRetryAfterHeader(now, header) {
        if (!header) {
            return defaultRetryAfter;
        }
        var headerDelay = parseInt("" + header, 10);
        if (!isNaN(headerDelay)) {
            return headerDelay * 1000;
        }
        var headerDate = Date.parse("" + header);
        if (!isNaN(headerDate)) {
            return headerDate - now;
        }
        return defaultRetryAfter;
    }
    function checkOrSetAlreadyCaught(exception) {
        var _a;
        if ((_a = exception) === null || _a === void 0 ? void 0 : _a.__sentry_captured__) {
            return true;
        }
        try {
            Object.defineProperty(exception, '__sentry_captured__', {
                value: true,
            });
        }
        catch (err) {
        }
        return false;
    }

    
    var States;
    (function (States) {
        States["PENDING"] = "PENDING";
        States["RESOLVED"] = "RESOLVED";
        States["REJECTED"] = "REJECTED";
    })(States || (States = {}));
    var SyncPromise =  (function () {
        function SyncPromise(executor) {
            var _this = this;
            this._state = States.PENDING;
            this._handlers = [];
            this._resolve = function (value) {
                _this._setResult(States.RESOLVED, value);
            };
            this._reject = function (reason) {
                _this._setResult(States.REJECTED, reason);
            };
            this._setResult = function (state, value) {
                if (_this._state !== States.PENDING) {
                    return;
                }
                if (isThenable(value)) {
                    void value.then(_this._resolve, _this._reject);
                    return;
                }
                _this._state = state;
                _this._value = value;
                _this._executeHandlers();
            };
            this._attachHandler = function (handler) {
                _this._handlers = _this._handlers.concat(handler);
                _this._executeHandlers();
            };
            this._executeHandlers = function () {
                if (_this._state === States.PENDING) {
                    return;
                }
                var cachedHandlers = _this._handlers.slice();
                _this._handlers = [];
                cachedHandlers.forEach(function (handler) {
                    if (handler.done) {
                        return;
                    }
                    if (_this._state === States.RESOLVED) {
                        if (handler.onfulfilled) {
                            handler.onfulfilled(_this._value);
                        }
                    }
                    if (_this._state === States.REJECTED) {
                        if (handler.onrejected) {
                            handler.onrejected(_this._value);
                        }
                    }
                    handler.done = true;
                });
            };
            try {
                executor(this._resolve, this._reject);
            }
            catch (e) {
                this._reject(e);
            }
        }
        SyncPromise.resolve = function (value) {
            return new SyncPromise(function (resolve) {
                resolve(value);
            });
        };
        SyncPromise.reject = function (reason) {
            return new SyncPromise(function (_, reject) {
                reject(reason);
            });
        };
        SyncPromise.all = function (collection) {
            return new SyncPromise(function (resolve, reject) {
                if (!Array.isArray(collection)) {
                    reject(new TypeError("Promise.all requires an array as input."));
                    return;
                }
                if (collection.length === 0) {
                    resolve([]);
                    return;
                }
                var counter = collection.length;
                var resolvedCollection = [];
                collection.forEach(function (item, index) {
                    void SyncPromise.resolve(item)
                        .then(function (value) {
                        resolvedCollection[index] = value;
                        counter -= 1;
                        if (counter !== 0) {
                            return;
                        }
                        resolve(resolvedCollection);
                    })
                        .then(null, reject);
                });
            });
        };
        SyncPromise.prototype.then = function (onfulfilled, onrejected) {
            var _this = this;
            return new SyncPromise(function (resolve, reject) {
                _this._attachHandler({
                    done: false,
                    onfulfilled: function (result) {
                        if (!onfulfilled) {
                            resolve(result);
                            return;
                        }
                        try {
                            resolve(onfulfilled(result));
                            return;
                        }
                        catch (e) {
                            reject(e);
                            return;
                        }
                    },
                    onrejected: function (reason) {
                        if (!onrejected) {
                            reject(reason);
                            return;
                        }
                        try {
                            resolve(onrejected(reason));
                            return;
                        }
                        catch (e) {
                            reject(e);
                            return;
                        }
                    },
                });
            });
        };
        SyncPromise.prototype.catch = function (onrejected) {
            return this.then(function (val) { return val; }, onrejected);
        };
        SyncPromise.prototype.finally = function (onfinally) {
            var _this = this;
            return new SyncPromise(function (resolve, reject) {
                var val;
                var isRejected;
                return _this.then(function (value) {
                    isRejected = false;
                    val = value;
                    if (onfinally) {
                        onfinally();
                    }
                }, function (reason) {
                    isRejected = true;
                    val = reason;
                    if (onfinally) {
                        onfinally();
                    }
                }).then(function () {
                    if (isRejected) {
                        reject(val);
                        return;
                    }
                    resolve(val);
                });
            });
        };
        SyncPromise.prototype.toString = function () {
            return '[object SyncPromise]';
        };
        return SyncPromise;
    }());

    
    var PromiseBuffer =  (function () {
        function PromiseBuffer(_limit) {
            this._limit = _limit;
            this._buffer = [];
        }
        PromiseBuffer.prototype.isReady = function () {
            return this._limit === undefined || this.length() < this._limit;
        };
        PromiseBuffer.prototype.add = function (taskProducer) {
            var _this = this;
            if (!this.isReady()) {
                return SyncPromise.reject(new SentryError('Not adding Promise due to buffer limit reached.'));
            }
            var task = taskProducer();
            if (this._buffer.indexOf(task) === -1) {
                this._buffer.push(task);
            }
            void task
                .then(function () { return _this.remove(task); })
                .then(null, function () {
                return _this.remove(task).then(null, function () {
                });
            });
            return task;
        };
        PromiseBuffer.prototype.remove = function (task) {
            var removedTask = this._buffer.splice(this._buffer.indexOf(task), 1)[0];
            return removedTask;
        };
        PromiseBuffer.prototype.length = function () {
            return this._buffer.length;
        };
        PromiseBuffer.prototype.drain = function (timeout) {
            var _this = this;
            return new SyncPromise(function (resolve) {
                var capturedSetTimeout = setTimeout(function () {
                    if (timeout && timeout > 0) {
                        resolve(false);
                    }
                }, timeout);
                void SyncPromise.all(_this._buffer)
                    .then(function () {
                    clearTimeout(capturedSetTimeout);
                    resolve(true);
                })
                    .then(null, function () {
                    resolve(true);
                });
            });
        };
        return PromiseBuffer;
    }());

    
    var dateTimestampSource = {
        nowSeconds: function () { return Date.now() / 1000; },
    };
    function getBrowserPerformance() {
        var performance = getGlobalObject().performance;
        if (!performance || !performance.now) {
            return undefined;
        }
        var timeOrigin = Date.now() - performance.now();
        return {
            now: function () { return performance.now(); },
            timeOrigin: timeOrigin,
        };
    }
    function getNodePerformance() {
        try {
            var perfHooks = dynamicRequire(module, 'perf_hooks');
            return perfHooks.performance;
        }
        catch (_) {
            return undefined;
        }
    }
    var platformPerformance = isNodeEnv() ? getNodePerformance() : getBrowserPerformance();
    var timestampSource = platformPerformance === undefined
        ? dateTimestampSource
        : {
            nowSeconds: function () { return (platformPerformance.timeOrigin + platformPerformance.now()) / 1000; },
        };
    var dateTimestampInSeconds = dateTimestampSource.nowSeconds.bind(dateTimestampSource);
    var timestampInSeconds = timestampSource.nowSeconds.bind(timestampSource);
    var browserPerformanceTimeOrigin = (function () {
        var performance = getGlobalObject().performance;
        if (!performance || !performance.now) {
            return undefined;
        }
        var threshold = 3600 * 1000;
        var performanceNow = performance.now();
        var dateNow = Date.now();
        var timeOriginDelta = performance.timeOrigin
            ? Math.abs(performance.timeOrigin + performanceNow - dateNow)
            : threshold;
        var timeOriginIsReliable = timeOriginDelta < threshold;
        var navigationStart = performance.timing && performance.timing.navigationStart;
        var hasNavigationStart = typeof navigationStart === 'number';
        var navigationStartDelta = hasNavigationStart ? Math.abs(navigationStart + performanceNow - dateNow) : threshold;
        var navigationStartIsReliable = navigationStartDelta < threshold;
        if (timeOriginIsReliable || navigationStartIsReliable) {
            if (timeOriginDelta <= navigationStartDelta) {
                return performance.timeOrigin;
            }
            else {
                return navigationStart;
            }
        }
        return dateNow;
    })();

    
    var MAX_BREADCRUMBS = 100;
    var Scope =  (function () {
        function Scope() {
            this._notifyingListeners = false;
            this._scopeListeners = [];
            this._eventProcessors = [];
            this._breadcrumbs = [];
            this._user = {};
            this._tags = {};
            this._extra = {};
            this._contexts = {};
        }
        Scope.clone = function (scope) {
            var newScope = new Scope();
            if (scope) {
                newScope._breadcrumbs = __spread(scope._breadcrumbs);
                newScope._tags = __assign({}, scope._tags);
                newScope._extra = __assign({}, scope._extra);
                newScope._contexts = __assign({}, scope._contexts);
                newScope._user = scope._user;
                newScope._level = scope._level;
                newScope._span = scope._span;
                newScope._session = scope._session;
                newScope._transactionName = scope._transactionName;
                newScope._fingerprint = scope._fingerprint;
                newScope._eventProcessors = __spread(scope._eventProcessors);
                newScope._requestSession = scope._requestSession;
            }
            return newScope;
        };
        Scope.prototype.addScopeListener = function (callback) {
            this._scopeListeners.push(callback);
        };
        Scope.prototype.addEventProcessor = function (callback) {
            this._eventProcessors.push(callback);
            return this;
        };
        Scope.prototype.setUser = function (user) {
            this._user = user || {};
            if (this._session) {
                this._session.update({ user: user });
            }
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.getUser = function () {
            return this._user;
        };
        Scope.prototype.getRequestSession = function () {
            return this._requestSession;
        };
        Scope.prototype.setRequestSession = function (requestSession) {
            this._requestSession = requestSession;
            return this;
        };
        Scope.prototype.setTags = function (tags) {
            this._tags = __assign(__assign({}, this._tags), tags);
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.setTag = function (key, value) {
            var _a;
            this._tags = __assign(__assign({}, this._tags), (_a = {}, _a[key] = value, _a));
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.setExtras = function (extras) {
            this._extra = __assign(__assign({}, this._extra), extras);
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.setExtra = function (key, extra) {
            var _a;
            this._extra = __assign(__assign({}, this._extra), (_a = {}, _a[key] = extra, _a));
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.setFingerprint = function (fingerprint) {
            this._fingerprint = fingerprint;
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.setLevel = function (level) {
            this._level = level;
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.setTransactionName = function (name) {
            this._transactionName = name;
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.setTransaction = function (name) {
            return this.setTransactionName(name);
        };
        Scope.prototype.setContext = function (key, context) {
            var _a;
            if (context === null) {
                delete this._contexts[key];
            }
            else {
                this._contexts = __assign(__assign({}, this._contexts), (_a = {}, _a[key] = context, _a));
            }
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.setSpan = function (span) {
            this._span = span;
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.getSpan = function () {
            return this._span;
        };
        Scope.prototype.getTransaction = function () {
            var _a, _b, _c, _d;
            var span = this.getSpan();
            if ((_a = span) === null || _a === void 0 ? void 0 : _a.transaction) {
                return (_b = span) === null || _b === void 0 ? void 0 : _b.transaction;
            }
            if ((_d = (_c = span) === null || _c === void 0 ? void 0 : _c.spanRecorder) === null || _d === void 0 ? void 0 : _d.spans[0]) {
                return span.spanRecorder.spans[0];
            }
            return undefined;
        };
        Scope.prototype.setSession = function (session) {
            if (!session) {
                delete this._session;
            }
            else {
                this._session = session;
            }
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.getSession = function () {
            return this._session;
        };
        Scope.prototype.update = function (captureContext) {
            if (!captureContext) {
                return this;
            }
            if (typeof captureContext === 'function') {
                var updatedScope = captureContext(this);
                return updatedScope instanceof Scope ? updatedScope : this;
            }
            if (captureContext instanceof Scope) {
                this._tags = __assign(__assign({}, this._tags), captureContext._tags);
                this._extra = __assign(__assign({}, this._extra), captureContext._extra);
                this._contexts = __assign(__assign({}, this._contexts), captureContext._contexts);
                if (captureContext._user && Object.keys(captureContext._user).length) {
                    this._user = captureContext._user;
                }
                if (captureContext._level) {
                    this._level = captureContext._level;
                }
                if (captureContext._fingerprint) {
                    this._fingerprint = captureContext._fingerprint;
                }
                if (captureContext._requestSession) {
                    this._requestSession = captureContext._requestSession;
                }
            }
            else if (isPlainObject(captureContext)) {
                captureContext = captureContext;
                this._tags = __assign(__assign({}, this._tags), captureContext.tags);
                this._extra = __assign(__assign({}, this._extra), captureContext.extra);
                this._contexts = __assign(__assign({}, this._contexts), captureContext.contexts);
                if (captureContext.user) {
                    this._user = captureContext.user;
                }
                if (captureContext.level) {
                    this._level = captureContext.level;
                }
                if (captureContext.fingerprint) {
                    this._fingerprint = captureContext.fingerprint;
                }
                if (captureContext.requestSession) {
                    this._requestSession = captureContext.requestSession;
                }
            }
            return this;
        };
        Scope.prototype.clear = function () {
            this._breadcrumbs = [];
            this._tags = {};
            this._extra = {};
            this._user = {};
            this._contexts = {};
            this._level = undefined;
            this._transactionName = undefined;
            this._fingerprint = undefined;
            this._requestSession = undefined;
            this._span = undefined;
            this._session = undefined;
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.addBreadcrumb = function (breadcrumb, maxBreadcrumbs) {
            var maxCrumbs = typeof maxBreadcrumbs === 'number' ? Math.min(maxBreadcrumbs, MAX_BREADCRUMBS) : MAX_BREADCRUMBS;
            if (maxCrumbs <= 0) {
                return this;
            }
            var mergedBreadcrumb = __assign({ timestamp: dateTimestampInSeconds() }, breadcrumb);
            this._breadcrumbs = __spread(this._breadcrumbs, [mergedBreadcrumb]).slice(-maxCrumbs);
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.clearBreadcrumbs = function () {
            this._breadcrumbs = [];
            this._notifyScopeListeners();
            return this;
        };
        Scope.prototype.applyToEvent = function (event, hint) {
            var _a;
            if (this._extra && Object.keys(this._extra).length) {
                event.extra = __assign(__assign({}, this._extra), event.extra);
            }
            if (this._tags && Object.keys(this._tags).length) {
                event.tags = __assign(__assign({}, this._tags), event.tags);
            }
            if (this._user && Object.keys(this._user).length) {
                event.user = __assign(__assign({}, this._user), event.user);
            }
            if (this._contexts && Object.keys(this._contexts).length) {
                event.contexts = __assign(__assign({}, this._contexts), event.contexts);
            }
            if (this._level) {
                event.level = this._level;
            }
            if (this._transactionName) {
                event.transaction = this._transactionName;
            }
            if (this._span) {
                event.contexts = __assign({ trace: this._span.getTraceContext() }, event.contexts);
                var transactionName = (_a = this._span.transaction) === null || _a === void 0 ? void 0 : _a.name;
                if (transactionName) {
                    event.tags = __assign({ transaction: transactionName }, event.tags);
                }
            }
            this._applyFingerprint(event);
            event.breadcrumbs = __spread((event.breadcrumbs || []), this._breadcrumbs);
            event.breadcrumbs = event.breadcrumbs.length > 0 ? event.breadcrumbs : undefined;
            return this._notifyEventProcessors(__spread(getGlobalEventProcessors(), this._eventProcessors), event, hint);
        };
        Scope.prototype._notifyEventProcessors = function (processors, event, hint, index) {
            var _this = this;
            if (index === void 0) { index = 0; }
            return new SyncPromise(function (resolve, reject) {
                var processor = processors[index];
                if (event === null || typeof processor !== 'function') {
                    resolve(event);
                }
                else {
                    var result = processor(__assign({}, event), hint);
                    if (isThenable(result)) {
                        void result
                            .then(function (final) { return _this._notifyEventProcessors(processors, final, hint, index + 1).then(resolve); })
                            .then(null, reject);
                    }
                    else {
                        void _this._notifyEventProcessors(processors, result, hint, index + 1)
                            .then(resolve)
                            .then(null, reject);
                    }
                }
            });
        };
        Scope.prototype._notifyScopeListeners = function () {
            var _this = this;
            if (!this._notifyingListeners) {
                this._notifyingListeners = true;
                this._scopeListeners.forEach(function (callback) {
                    callback(_this);
                });
                this._notifyingListeners = false;
            }
        };
        Scope.prototype._applyFingerprint = function (event) {
            event.fingerprint = event.fingerprint
                ? Array.isArray(event.fingerprint)
                    ? event.fingerprint
                    : [event.fingerprint]
                : [];
            if (this._fingerprint) {
                event.fingerprint = event.fingerprint.concat(this._fingerprint);
            }
            if (event.fingerprint && !event.fingerprint.length) {
                delete event.fingerprint;
            }
        };
        return Scope;
    }());
    function getGlobalEventProcessors() {
        var global = getGlobalObject();
        global.__SENTRY__ = global.__SENTRY__ || {};
        global.__SENTRY__.globalEventProcessors = global.__SENTRY__.globalEventProcessors || [];
        return global.__SENTRY__.globalEventProcessors;
    }
    function addGlobalEventProcessor(callback) {
        getGlobalEventProcessors().push(callback);
    }

    
    var Session =  (function () {
        function Session(context) {
            this.errors = 0;
            this.sid = uuid4();
            this.duration = 0;
            this.status = SessionStatus.Ok;
            this.init = true;
            this.ignoreDuration = false;
            var startingTime = timestampInSeconds();
            this.timestamp = startingTime;
            this.started = startingTime;
            if (context) {
                this.update(context);
            }
        }
        Session.prototype.update = function (context) {
            if (context === void 0) { context = {}; }
            if (context.user) {
                if (!this.ipAddress && context.user.ip_address) {
                    this.ipAddress = context.user.ip_address;
                }
                if (!this.did && !context.did) {
                    this.did = context.user.id || context.user.email || context.user.username;
                }
            }
            this.timestamp = context.timestamp || timestampInSeconds();
            if (context.ignoreDuration) {
                this.ignoreDuration = context.ignoreDuration;
            }
            if (context.sid) {
                this.sid = context.sid.length === 32 ? context.sid : uuid4();
            }
            if (context.init !== undefined) {
                this.init = context.init;
            }
            if (!this.did && context.did) {
                this.did = "" + context.did;
            }
            if (typeof context.started === 'number') {
                this.started = context.started;
            }
            if (this.ignoreDuration) {
                this.duration = undefined;
            }
            else if (typeof context.duration === 'number') {
                this.duration = context.duration;
            }
            else {
                var duration = this.timestamp - this.started;
                this.duration = duration >= 0 ? duration : 0;
            }
            if (context.release) {
                this.release = context.release;
            }
            if (context.environment) {
                this.environment = context.environment;
            }
            if (!this.ipAddress && context.ipAddress) {
                this.ipAddress = context.ipAddress;
            }
            if (!this.userAgent && context.userAgent) {
                this.userAgent = context.userAgent;
            }
            if (typeof context.errors === 'number') {
                this.errors = context.errors;
            }
            if (context.status) {
                this.status = context.status;
            }
        };
        Session.prototype.close = function (status) {
            if (status) {
                this.update({ status: status });
            }
            else if (this.status === SessionStatus.Ok) {
                this.update({ status: SessionStatus.Exited });
            }
            else {
                this.update();
            }
        };
        Session.prototype.toJSON = function () {
            return dropUndefinedKeys({
                sid: "" + this.sid,
                init: this.init,
                started: new Date(this.started * 1000).toISOString(),
                timestamp: new Date(this.timestamp * 1000).toISOString(),
                status: this.status,
                errors: this.errors,
                did: typeof this.did === 'number' || typeof this.did === 'string' ? "" + this.did : undefined,
                duration: this.duration,
                attrs: dropUndefinedKeys({
                    release: this.release,
                    environment: this.environment,
                    ip_address: this.ipAddress,
                    user_agent: this.userAgent,
                }),
            });
        };
        return Session;
    }());

    
    var API_VERSION = 4;
    var DEFAULT_BREADCRUMBS = 100;
    var Hub =  (function () {
        function Hub(client, scope, _version) {
            if (scope === void 0) { scope = new Scope(); }
            if (_version === void 0) { _version = API_VERSION; }
            this._version = _version;
            this._stack = [{}];
            this.getStackTop().scope = scope;
            if (client) {
                this.bindClient(client);
            }
        }
        Hub.prototype.isOlderThan = function (version) {
            return this._version < version;
        };
        Hub.prototype.bindClient = function (client) {
            var top = this.getStackTop();
            top.client = client;
            if (client && client.setupIntegrations) {
                client.setupIntegrations();
            }
        };
        Hub.prototype.pushScope = function () {
            var scope = Scope.clone(this.getScope());
            this.getStack().push({
                client: this.getClient(),
                scope: scope,
            });
            return scope;
        };
        Hub.prototype.popScope = function () {
            if (this.getStack().length <= 1)
                return false;
            return !!this.getStack().pop();
        };
        Hub.prototype.withScope = function (callback) {
            var scope = this.pushScope();
            try {
                callback(scope);
            }
            finally {
                this.popScope();
            }
        };
        Hub.prototype.getClient = function () {
            return this.getStackTop().client;
        };
        Hub.prototype.getScope = function () {
            return this.getStackTop().scope;
        };
        Hub.prototype.getStack = function () {
            return this._stack;
        };
        Hub.prototype.getStackTop = function () {
            return this._stack[this._stack.length - 1];
        };
        Hub.prototype.captureException = function (exception, hint) {
            var eventId = (this._lastEventId = uuid4());
            var finalHint = hint;
            if (!hint) {
                var syntheticException = void 0;
                try {
                    throw new Error('Sentry syntheticException');
                }
                catch (exception) {
                    syntheticException = exception;
                }
                finalHint = {
                    originalException: exception,
                    syntheticException: syntheticException,
                };
            }
            this._invokeClient('captureException', exception, __assign(__assign({}, finalHint), { event_id: eventId }));
            return eventId;
        };
        Hub.prototype.captureMessage = function (message, level, hint) {
            var eventId = (this._lastEventId = uuid4());
            var finalHint = hint;
            if (!hint) {
                var syntheticException = void 0;
                try {
                    throw new Error(message);
                }
                catch (exception) {
                    syntheticException = exception;
                }
                finalHint = {
                    originalException: message,
                    syntheticException: syntheticException,
                };
            }
            this._invokeClient('captureMessage', message, level, __assign(__assign({}, finalHint), { event_id: eventId }));
            return eventId;
        };
        Hub.prototype.captureEvent = function (event, hint) {
            var eventId = uuid4();
            if (event.type !== 'transaction') {
                this._lastEventId = eventId;
            }
            this._invokeClient('captureEvent', event, __assign(__assign({}, hint), { event_id: eventId }));
            return eventId;
        };
        Hub.prototype.lastEventId = function () {
            return this._lastEventId;
        };
        Hub.prototype.addBreadcrumb = function (breadcrumb, hint) {
            var _a = this.getStackTop(), scope = _a.scope, client = _a.client;
            if (!scope || !client)
                return;
            var _b = (client.getOptions && client.getOptions()) || {}, _c = _b.beforeBreadcrumb, beforeBreadcrumb = _c === void 0 ? null : _c, _d = _b.maxBreadcrumbs, maxBreadcrumbs = _d === void 0 ? DEFAULT_BREADCRUMBS : _d;
            if (maxBreadcrumbs <= 0)
                return;
            var timestamp = dateTimestampInSeconds();
            var mergedBreadcrumb = __assign({ timestamp: timestamp }, breadcrumb);
            var finalBreadcrumb = beforeBreadcrumb
                ? consoleSandbox(function () { return beforeBreadcrumb(mergedBreadcrumb, hint); })
                : mergedBreadcrumb;
            if (finalBreadcrumb === null)
                return;
            scope.addBreadcrumb(finalBreadcrumb, maxBreadcrumbs);
        };
        Hub.prototype.setUser = function (user) {
            var scope = this.getScope();
            if (scope)
                scope.setUser(user);
        };
        Hub.prototype.setTags = function (tags) {
            var scope = this.getScope();
            if (scope)
                scope.setTags(tags);
        };
        Hub.prototype.setExtras = function (extras) {
            var scope = this.getScope();
            if (scope)
                scope.setExtras(extras);
        };
        Hub.prototype.setTag = function (key, value) {
            var scope = this.getScope();
            if (scope)
                scope.setTag(key, value);
        };
        Hub.prototype.setExtra = function (key, extra) {
            var scope = this.getScope();
            if (scope)
                scope.setExtra(key, extra);
        };
        Hub.prototype.setContext = function (name, context) {
            var scope = this.getScope();
            if (scope)
                scope.setContext(name, context);
        };
        Hub.prototype.configureScope = function (callback) {
            var _a = this.getStackTop(), scope = _a.scope, client = _a.client;
            if (scope && client) {
                callback(scope);
            }
        };
        Hub.prototype.run = function (callback) {
            var oldHub = makeMain(this);
            try {
                callback(this);
            }
            finally {
                makeMain(oldHub);
            }
        };
        Hub.prototype.getIntegration = function (integration) {
            var client = this.getClient();
            if (!client)
                return null;
            try {
                return client.getIntegration(integration);
            }
            catch (_oO) {
                logger.warn("Cannot retrieve integration " + integration.id + " from the current Hub");
                return null;
            }
        };
        Hub.prototype.startSpan = function (context) {
            return this._callExtensionMethod('startSpan', context);
        };
        Hub.prototype.startTransaction = function (context, customSamplingContext) {
            return this._callExtensionMethod('startTransaction', context, customSamplingContext);
        };
        Hub.prototype.traceHeaders = function () {
            return this._callExtensionMethod('traceHeaders');
        };
        Hub.prototype.captureSession = function (endSession) {
            if (endSession === void 0) { endSession = false; }
            if (endSession) {
                return this.endSession();
            }
            this._sendSessionUpdate();
        };
        Hub.prototype.endSession = function () {
            var _a, _b, _c, _d, _e;
            (_c = (_b = (_a = this.getStackTop()) === null || _a === void 0 ? void 0 : _a.scope) === null || _b === void 0 ? void 0 : _b.getSession()) === null || _c === void 0 ? void 0 : _c.close();
            this._sendSessionUpdate();
            (_e = (_d = this.getStackTop()) === null || _d === void 0 ? void 0 : _d.scope) === null || _e === void 0 ? void 0 : _e.setSession();
        };
        Hub.prototype.startSession = function (context) {
            var _a = this.getStackTop(), scope = _a.scope, client = _a.client;
            var _b = (client && client.getOptions()) || {}, release = _b.release, environment = _b.environment;
            var global = getGlobalObject();
            var userAgent = (global.navigator || {}).userAgent;
            var session = new Session(__assign(__assign(__assign({ release: release,
                environment: environment }, (scope && { user: scope.getUser() })), (userAgent && { userAgent: userAgent })), context));
            if (scope) {
                var currentSession = scope.getSession && scope.getSession();
                if (currentSession && currentSession.status === SessionStatus.Ok) {
                    currentSession.update({ status: SessionStatus.Exited });
                }
                this.endSession();
                scope.setSession(session);
            }
            return session;
        };
        Hub.prototype._sendSessionUpdate = function () {
            var _a = this.getStackTop(), scope = _a.scope, client = _a.client;
            if (!scope)
                return;
            var session = scope.getSession && scope.getSession();
            if (session) {
                if (client && client.captureSession) {
                    client.captureSession(session);
                }
            }
        };
        Hub.prototype._invokeClient = function (method) {
            var _a;
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            var _b = this.getStackTop(), scope = _b.scope, client = _b.client;
            if (client && client[method]) {
                (_a = client)[method].apply(_a, __spread(args, [scope]));
            }
        };
        Hub.prototype._callExtensionMethod = function (method) {
            var args = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args[_i - 1] = arguments[_i];
            }
            var carrier = getMainCarrier();
            var sentry = carrier.__SENTRY__;
            if (sentry && sentry.extensions && typeof sentry.extensions[method] === 'function') {
                return sentry.extensions[method].apply(this, args);
            }
            logger.warn("Extension method " + method + " couldn't be found, doing nothing.");
        };
        return Hub;
    }());
    function getMainCarrier() {
        var carrier = getGlobalObject();
        carrier.__SENTRY__ = carrier.__SENTRY__ || {
            extensions: {},
            hub: undefined,
        };
        return carrier;
    }
    function makeMain(hub) {
        var registry = getMainCarrier();
        var oldHub = getHubFromCarrier(registry);
        setHubOnCarrier(registry, hub);
        return oldHub;
    }
    function getCurrentHub() {
        var registry = getMainCarrier();
        if (!hasHubOnCarrier(registry) || getHubFromCarrier(registry).isOlderThan(API_VERSION)) {
            setHubOnCarrier(registry, new Hub());
        }
        if (isNodeEnv()) {
            return getHubFromActiveDomain(registry);
        }
        return getHubFromCarrier(registry);
    }
    function getHubFromActiveDomain(registry) {
        var _a, _b, _c;
        try {
            var activeDomain = (_c = (_b = (_a = getMainCarrier().__SENTRY__) === null || _a === void 0 ? void 0 : _a.extensions) === null || _b === void 0 ? void 0 : _b.domain) === null || _c === void 0 ? void 0 : _c.active;
            if (!activeDomain) {
                return getHubFromCarrier(registry);
            }
            if (!hasHubOnCarrier(activeDomain) || getHubFromCarrier(activeDomain).isOlderThan(API_VERSION)) {
                var registryHubTopStack = getHubFromCarrier(registry).getStackTop();
                setHubOnCarrier(activeDomain, new Hub(registryHubTopStack.client, Scope.clone(registryHubTopStack.scope)));
            }
            return getHubFromCarrier(activeDomain);
        }
        catch (_Oo) {
            return getHubFromCarrier(registry);
        }
    }
    function hasHubOnCarrier(carrier) {
        return !!(carrier && carrier.__SENTRY__ && carrier.__SENTRY__.hub);
    }
    function getHubFromCarrier(carrier) {
        if (carrier && carrier.__SENTRY__ && carrier.__SENTRY__.hub)
            return carrier.__SENTRY__.hub;
        carrier.__SENTRY__ = carrier.__SENTRY__ || {};
        carrier.__SENTRY__.hub = new Hub();
        return carrier.__SENTRY__.hub;
    }
    function setHubOnCarrier(carrier, hub) {
        if (!carrier)
            return false;
        carrier.__SENTRY__ = carrier.__SENTRY__ || {};
        carrier.__SENTRY__.hub = hub;
        return true;
    }

    
    function callOnHub(method) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var hub = getCurrentHub();
        if (hub && hub[method]) {
            return hub[method].apply(hub, __spread(args));
        }
        throw new Error("No hub defined or " + method + " was not found on the hub, please open a bug report.");
    }
    function captureException(exception, captureContext) {
        var syntheticException;
        try {
            throw new Error('Sentry syntheticException');
        }
        catch (exception) {
            syntheticException = exception;
        }
        return callOnHub('captureException', exception, {
            captureContext: captureContext,
            originalException: exception,
            syntheticException: syntheticException,
        });
    }
    function captureMessage(message, captureContext) {
        var syntheticException;
        try {
            throw new Error(message);
        }
        catch (exception) {
            syntheticException = exception;
        }
        var level = typeof captureContext === 'string' ? captureContext : undefined;
        var context = typeof captureContext !== 'string' ? { captureContext: captureContext } : undefined;
        return callOnHub('captureMessage', message, level, __assign({ originalException: message, syntheticException: syntheticException }, context));
    }
    function captureEvent(event) {
        return callOnHub('captureEvent', event);
    }
    function configureScope(callback) {
        callOnHub('configureScope', callback);
    }
    function addBreadcrumb(breadcrumb) {
        callOnHub('addBreadcrumb', breadcrumb);
    }
    function setContext(name, context) {
        callOnHub('setContext', name, context);
    }
    function setExtras(extras) {
        callOnHub('setExtras', extras);
    }
    function setTags(tags) {
        callOnHub('setTags', tags);
    }
    function setExtra(key, extra) {
        callOnHub('setExtra', key, extra);
    }
    function setTag(key, value) {
        callOnHub('setTag', key, value);
    }
    function setUser(user) {
        callOnHub('setUser', user);
    }
    function withScope(callback) {
        callOnHub('withScope', callback);
    }
    function startTransaction(context, customSamplingContext) {
        return callOnHub('startTransaction', __assign({}, context), customSamplingContext);
    }

    var SENTRY_API_VERSION = '7';
    var API =  (function () {
        function API(dsn, metadata, tunnel) {
            if (metadata === void 0) { metadata = {}; }
            this.dsn = dsn;
            this._dsnObject = new Dsn(dsn);
            this.metadata = metadata;
            this._tunnel = tunnel;
        }
        API.prototype.getDsn = function () {
            return this._dsnObject;
        };
        API.prototype.forceEnvelope = function () {
            return !!this._tunnel;
        };
        API.prototype.getBaseApiEndpoint = function () {
            var dsn = this.getDsn();
            var protocol = dsn.protocol ? dsn.protocol + ":" : '';
            var port = dsn.port ? ":" + dsn.port : '';
            return protocol + "//" + dsn.host + port + (dsn.path ? "/" + dsn.path : '') + "/api/";
        };
        API.prototype.getStoreEndpoint = function () {
            return this._getIngestEndpoint('store');
        };
        API.prototype.getStoreEndpointWithUrlEncodedAuth = function () {
            return this.getStoreEndpoint() + "?" + this._encodedAuth();
        };
        API.prototype.getEnvelopeEndpointWithUrlEncodedAuth = function () {
            if (this.forceEnvelope()) {
                return this._tunnel;
            }
            return this._getEnvelopeEndpoint() + "?" + this._encodedAuth();
        };
        API.prototype.getStoreEndpointPath = function () {
            var dsn = this.getDsn();
            return (dsn.path ? "/" + dsn.path : '') + "/api/" + dsn.projectId + "/store/";
        };
        API.prototype.getRequestHeaders = function (clientName, clientVersion) {
            var dsn = this.getDsn();
            var header = ["Sentry sentry_version=" + SENTRY_API_VERSION];
            header.push("sentry_client=" + clientName + "/" + clientVersion);
            header.push("sentry_key=" + dsn.publicKey);
            if (dsn.pass) {
                header.push("sentry_secret=" + dsn.pass);
            }
            return {
                'Content-Type': 'application/json',
                'X-Sentry-Auth': header.join(', '),
            };
        };
        API.prototype.getReportDialogEndpoint = function (dialogOptions) {
            if (dialogOptions === void 0) { dialogOptions = {}; }
            var dsn = this.getDsn();
            var endpoint = this.getBaseApiEndpoint() + "embed/error-page/";
            var encodedOptions = [];
            encodedOptions.push("dsn=" + dsn.toString());
            for (var key in dialogOptions) {
                if (key === 'dsn') {
                    continue;
                }
                if (key === 'user') {
                    if (!dialogOptions.user) {
                        continue;
                    }
                    if (dialogOptions.user.name) {
                        encodedOptions.push("name=" + encodeURIComponent(dialogOptions.user.name));
                    }
                    if (dialogOptions.user.email) {
                        encodedOptions.push("email=" + encodeURIComponent(dialogOptions.user.email));
                    }
                }
                else {
                    encodedOptions.push(encodeURIComponent(key) + "=" + encodeURIComponent(dialogOptions[key]));
                }
            }
            if (encodedOptions.length) {
                return endpoint + "?" + encodedOptions.join('&');
            }
            return endpoint;
        };
        API.prototype._getEnvelopeEndpoint = function () {
            return this._getIngestEndpoint('envelope');
        };
        API.prototype._getIngestEndpoint = function (target) {
            if (this._tunnel) {
                return this._tunnel;
            }
            var base = this.getBaseApiEndpoint();
            var dsn = this.getDsn();
            return "" + base + dsn.projectId + "/" + target + "/";
        };
        API.prototype._encodedAuth = function () {
            var dsn = this.getDsn();
            var auth = {
                sentry_key: dsn.publicKey,
                sentry_version: SENTRY_API_VERSION,
            };
            return urlEncode(auth);
        };
        return API;
    }());

    var installedIntegrations = [];
    function filterDuplicates(integrations) {
        return integrations.reduce(function (acc, integrations) {
            if (acc.every(function (accIntegration) { return integrations.name !== accIntegration.name; })) {
                acc.push(integrations);
            }
            return acc;
        }, []);
    }
    function getIntegrationsToSetup(options) {
        var defaultIntegrations = (options.defaultIntegrations && __spread(options.defaultIntegrations)) || [];
        var userIntegrations = options.integrations;
        var integrations = __spread(filterDuplicates(defaultIntegrations));
        if (Array.isArray(userIntegrations)) {
            integrations = __spread(integrations.filter(function (integrations) {
                return userIntegrations.every(function (userIntegration) { return userIntegration.name !== integrations.name; });
            }), filterDuplicates(userIntegrations));
        }
        else if (typeof userIntegrations === 'function') {
            integrations = userIntegrations(integrations);
            integrations = Array.isArray(integrations) ? integrations : [integrations];
        }
        var integrationsNames = integrations.map(function (i) { return i.name; });
        var alwaysLastToRun = 'Debug';
        if (integrationsNames.indexOf(alwaysLastToRun) !== -1) {
            integrations.push.apply(integrations, __spread(integrations.splice(integrationsNames.indexOf(alwaysLastToRun), 1)));
        }
        return integrations;
    }
    function setupIntegration(integration) {
        if (installedIntegrations.indexOf(integration.name) !== -1) {
            return;
        }
        integration.setupOnce(addGlobalEventProcessor, getCurrentHub);
        installedIntegrations.push(integration.name);
        logger.log("Integration installed: " + integration.name);
    }
    function setupIntegrations(options) {
        var integrations = {};
        getIntegrationsToSetup(options).forEach(function (integration) {
            integrations[integration.name] = integration;
            setupIntegration(integration);
        });
        Object.defineProperty(integrations, 'initialized', { value: true });
        return integrations;
    }

    var ALREADY_SEEN_ERROR = "Not capturing exception because it's already been captured.";
    var BaseClient =  (function () {
        function BaseClient(backendClass, options) {
            this._integrations = {};
            this._numProcessing = 0;
            this._backend = new backendClass(options);
            this._options = options;
            if (options.dsn) {
                this._dsn = new Dsn(options.dsn);
            }
        }
        BaseClient.prototype.captureException = function (exception, hint, scope) {
            var _this = this;
            if (checkOrSetAlreadyCaught(exception)) {
                logger.log(ALREADY_SEEN_ERROR);
                return;
            }
            var eventId = hint && hint.event_id;
            this._process(this._getBackend()
                .eventFromException(exception, hint)
                .then(function (event) { return _this._captureEvent(event, hint, scope); })
                .then(function (result) {
                eventId = result;
            }));
            return eventId;
        };
        BaseClient.prototype.captureMessage = function (message, level, hint, scope) {
            var _this = this;
            var eventId = hint && hint.event_id;
            var promisedEvent = isPrimitive(message)
                ? this._getBackend().eventFromMessage(String(message), level, hint)
                : this._getBackend().eventFromException(message, hint);
            this._process(promisedEvent
                .then(function (event) { return _this._captureEvent(event, hint, scope); })
                .then(function (result) {
                eventId = result;
            }));
            return eventId;
        };
        BaseClient.prototype.captureEvent = function (event, hint, scope) {
            var _a;
            if (((_a = hint) === null || _a === void 0 ? void 0 : _a.originalException) && checkOrSetAlreadyCaught(hint.originalException)) {
                logger.log(ALREADY_SEEN_ERROR);
                return;
            }
            var eventId = hint && hint.event_id;
            this._process(this._captureEvent(event, hint, scope).then(function (result) {
                eventId = result;
            }));
            return eventId;
        };
        BaseClient.prototype.captureSession = function (session) {
            if (!this._isEnabled()) {
                logger.warn('SDK not enabled, will not capture session.');
                return;
            }
            if (!(typeof session.release === 'string')) {
                logger.warn('Discarded session because of missing or non-string release');
            }
            else {
                this._sendSession(session);
                session.update({ init: false });
            }
        };
        BaseClient.prototype.getDsn = function () {
            return this._dsn;
        };
        BaseClient.prototype.getOptions = function () {
            return this._options;
        };
        BaseClient.prototype.getTransport = function () {
            return this._getBackend().getTransport();
        };
        BaseClient.prototype.flush = function (timeout) {
            var _this = this;
            return this._isClientDoneProcessing(timeout).then(function (clientFinished) {
                return _this.getTransport()
                    .close(timeout)
                    .then(function (transportFlushed) { return clientFinished && transportFlushed; });
            });
        };
        BaseClient.prototype.close = function (timeout) {
            var _this = this;
            return this.flush(timeout).then(function (result) {
                _this.getOptions().enabled = false;
                return result;
            });
        };
        BaseClient.prototype.setupIntegrations = function () {
            if (this._isEnabled() && !this._integrations.initialized) {
                this._integrations = setupIntegrations(this._options);
            }
        };
        BaseClient.prototype.getIntegration = function (integration) {
            try {
                return this._integrations[integration.id] || null;
            }
            catch (_oO) {
                logger.warn("Cannot retrieve integration " + integration.id + " from the current Client");
                return null;
            }
        };
        BaseClient.prototype._updateSessionFromEvent = function (session, event) {
            var e_1, _a;
            var crashed = false;
            var errored = false;
            var exceptions = event.exception && event.exception.values;
            if (exceptions) {
                errored = true;
                try {
                    for (var exceptions_1 = __values(exceptions), exceptions_1_1 = exceptions_1.next(); !exceptions_1_1.done; exceptions_1_1 = exceptions_1.next()) {
                        var ex = exceptions_1_1.value;
                        var mechanism = ex.mechanism;
                        if (mechanism && mechanism.handled === false) {
                            crashed = true;
                            break;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (exceptions_1_1 && !exceptions_1_1.done && (_a = exceptions_1.return)) _a.call(exceptions_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            var sessionNonTerminal = session.status === SessionStatus.Ok;
            var shouldUpdateAndSend = (sessionNonTerminal && session.errors === 0) || (sessionNonTerminal && crashed);
            if (shouldUpdateAndSend) {
                session.update(__assign(__assign({}, (crashed && { status: SessionStatus.Crashed })), { errors: session.errors || Number(errored || crashed) }));
                this.captureSession(session);
            }
        };
        BaseClient.prototype._sendSession = function (session) {
            this._getBackend().sendSession(session);
        };
        BaseClient.prototype._isClientDoneProcessing = function (timeout) {
            var _this = this;
            return new SyncPromise(function (resolve) {
                var ticked = 0;
                var tick = 1;
                var interval = setInterval(function () {
                    if (_this._numProcessing == 0) {
                        clearInterval(interval);
                        resolve(true);
                    }
                    else {
                        ticked += tick;
                        if (timeout && ticked >= timeout) {
                            clearInterval(interval);
                            resolve(false);
                        }
                    }
                }, tick);
            });
        };
        BaseClient.prototype._getBackend = function () {
            return this._backend;
        };
        BaseClient.prototype._isEnabled = function () {
            return this.getOptions().enabled !== false && this._dsn !== undefined;
        };
        BaseClient.prototype._prepareEvent = function (event, scope, hint) {
            var _this = this;
            var _a = this.getOptions().normalizeDepth, normalizeDepth = _a === void 0 ? 3 : _a;
            var prepared = __assign(__assign({}, event), { event_id: event.event_id || (hint && hint.event_id ? hint.event_id : uuid4()), timestamp: event.timestamp || dateTimestampInSeconds() });
            this._applyClientOptions(prepared);
            this._applyIntegrationsMetadata(prepared);
            var finalScope = scope;
            if (hint && hint.captureContext) {
                finalScope = Scope.clone(finalScope).update(hint.captureContext);
            }
            var result = SyncPromise.resolve(prepared);
            if (finalScope) {
                result = finalScope.applyToEvent(prepared, hint);
            }
            return result.then(function (evt) {
                if (typeof normalizeDepth === 'number' && normalizeDepth > 0) {
                    return _this._normalizeEvent(evt, normalizeDepth);
                }
                return evt;
            });
        };
        BaseClient.prototype._normalizeEvent = function (event, depth) {
            if (!event) {
                return null;
            }
            var normalized = __assign(__assign(__assign(__assign(__assign({}, event), (event.breadcrumbs && {
                breadcrumbs: event.breadcrumbs.map(function (b) { return (__assign(__assign({}, b), (b.data && {
                    data: normalize(b.data, depth),
                }))); }),
            })), (event.user && {
                user: normalize(event.user, depth),
            })), (event.contexts && {
                contexts: normalize(event.contexts, depth),
            })), (event.extra && {
                extra: normalize(event.extra, depth),
            }));
            if (event.contexts && event.contexts.trace) {
                normalized.contexts.trace = event.contexts.trace;
            }
            var _a = this.getOptions()._experiments, _experiments = _a === void 0 ? {} : _a;
            if (_experiments.ensureNoCircularStructures) {
                return normalize(normalized);
            }
            return normalized;
        };
        BaseClient.prototype._applyClientOptions = function (event) {
            var options = this.getOptions();
            var environment = options.environment, release = options.release, dist = options.dist, _a = options.maxValueLength, maxValueLength = _a === void 0 ? 250 : _a;
            if (!('environment' in event)) {
                event.environment = 'environment' in options ? environment : 'production';
            }
            if (event.release === undefined && release !== undefined) {
                event.release = release;
            }
            if (event.dist === undefined && dist !== undefined) {
                event.dist = dist;
            }
            if (event.message) {
                event.message = truncate(event.message, maxValueLength);
            }
            var exception = event.exception && event.exception.values && event.exception.values[0];
            if (exception && exception.value) {
                exception.value = truncate(exception.value, maxValueLength);
            }
            var request = event.request;
            if (request && request.url) {
                request.url = truncate(request.url, maxValueLength);
            }
        };
        BaseClient.prototype._applyIntegrationsMetadata = function (event) {
            var integrationsArray = Object.keys(this._integrations);
            if (integrationsArray.length > 0) {
                event.sdk = event.sdk || {};
                event.sdk.integrations = __spread((event.sdk.integrations || []), integrationsArray);
            }
        };
        BaseClient.prototype._sendEvent = function (event) {
            this._getBackend().sendEvent(event);
        };
        BaseClient.prototype._captureEvent = function (event, hint, scope) {
            return this._processEvent(event, hint, scope).then(function (finalEvent) {
                return finalEvent.event_id;
            }, function (reason) {
                logger.error(reason);
                return undefined;
            });
        };
        BaseClient.prototype._processEvent = function (event, hint, scope) {
            var _this = this;
            var _a, _b;
            var _c = this.getOptions(), beforeSend = _c.beforeSend, sampleRate = _c.sampleRate;
            var transport = this.getTransport();
            if (!this._isEnabled()) {
                return SyncPromise.reject(new SentryError('SDK not enabled, will not capture event.'));
            }
            var isTransaction = event.type === 'transaction';
            if (!isTransaction && typeof sampleRate === 'number' && Math.random() > sampleRate) {
                (_b = (_a = transport).recordLostEvent) === null || _b === void 0 ? void 0 : _b.call(_a, Outcome.SampleRate, 'event');
                return SyncPromise.reject(new SentryError("Discarding event because it's not included in the random sample (sampling rate = " + sampleRate + ")"));
            }
            return this._prepareEvent(event, scope, hint)
                .then(function (prepared) {
                var _a, _b;
                if (prepared === null) {
                    (_b = (_a = transport).recordLostEvent) === null || _b === void 0 ? void 0 : _b.call(_a, Outcome.EventProcessor, event.type || 'event');
                    throw new SentryError('An event processor returned null, will not send event.');
                }
                var isInternalException = hint && hint.data && hint.data.__sentry__ === true;
                if (isInternalException || isTransaction || !beforeSend) {
                    return prepared;
                }
                var beforeSendResult = beforeSend(prepared, hint);
                return _this._ensureBeforeSendRv(beforeSendResult);
            })
                .then(function (processedEvent) {
                var _a, _b;
                if (processedEvent === null) {
                    (_b = (_a = transport).recordLostEvent) === null || _b === void 0 ? void 0 : _b.call(_a, Outcome.BeforeSend, event.type || 'event');
                    throw new SentryError('`beforeSend` returned `null`, will not send event.');
                }
                var session = scope && scope.getSession && scope.getSession();
                if (!isTransaction && session) {
                    _this._updateSessionFromEvent(session, processedEvent);
                }
                _this._sendEvent(processedEvent);
                return processedEvent;
            })
                .then(null, function (reason) {
                if (reason instanceof SentryError) {
                    throw reason;
                }
                _this.captureException(reason, {
                    data: {
                        __sentry__: true,
                    },
                    originalException: reason,
                });
                throw new SentryError("Event processing pipeline threw an error, original event will not be sent. Details have been sent as a new event.\nReason: " + reason);
            });
        };
        BaseClient.prototype._process = function (promise) {
            var _this = this;
            this._numProcessing += 1;
            void promise.then(function (value) {
                _this._numProcessing -= 1;
                return value;
            }, function (reason) {
                _this._numProcessing -= 1;
                return reason;
            });
        };
        BaseClient.prototype._ensureBeforeSendRv = function (rv) {
            var nullErr = '`beforeSend` method has to return `null` or a valid event.';
            if (isThenable(rv)) {
                return rv.then(function (event) {
                    if (!(isPlainObject(event) || event === null)) {
                        throw new SentryError(nullErr);
                    }
                    return event;
                }, function (e) {
                    throw new SentryError("beforeSend rejected with " + e);
                });
            }
            else if (!(isPlainObject(rv) || rv === null)) {
                throw new SentryError(nullErr);
            }
            return rv;
        };
        return BaseClient;
    }());

    
    var NoopTransport =  (function () {
        function NoopTransport() {
        }
        NoopTransport.prototype.sendEvent = function (_) {
            return SyncPromise.resolve({
                reason: "NoopTransport: Event has been skipped because no Dsn is configured.",
                status: exports.Status.Skipped,
            });
        };
        NoopTransport.prototype.close = function (_) {
            return SyncPromise.resolve(true);
        };
        return NoopTransport;
    }());

    
    var BaseBackend =  (function () {
        function BaseBackend(options) {
            this._options = options;
            if (!this._options.dsn) {
                logger.warn('No DSN provided, backend will not do anything.');
            }
            this._transport = this._setupTransport();
        }
        BaseBackend.prototype.eventFromException = function (_exception, _hint) {
            throw new SentryError('Backend has to implement `eventFromException` method');
        };
        BaseBackend.prototype.eventFromMessage = function (_message, _level, _hint) {
            throw new SentryError('Backend has to implement `eventFromMessage` method');
        };
        BaseBackend.prototype.sendEvent = function (event) {
            void this._transport.sendEvent(event).then(null, function (reason) {
                logger.error("Error while sending event: " + reason);
            });
        };
        BaseBackend.prototype.sendSession = function (session) {
            if (!this._transport.sendSession) {
                logger.warn("Dropping session because custom transport doesn't implement sendSession");
                return;
            }
            void this._transport.sendSession(session).then(null, function (reason) {
                logger.error("Error while sending session: " + reason);
            });
        };
        BaseBackend.prototype.getTransport = function () {
            return this._transport;
        };
        BaseBackend.prototype._setupTransport = function () {
            return new NoopTransport();
        };
        return BaseBackend;
    }());

    
    function getSdkMetadataForEnvelopeHeader(api) {
        if (!api.metadata || !api.metadata.sdk) {
            return;
        }
        var _a = api.metadata.sdk, name = _a.name, version = _a.version;
        return { name: name, version: version };
    }
    function enhanceEventWithSdkInfo(event, sdkInfo) {
        if (!sdkInfo) {
            return event;
        }
        event.sdk = event.sdk || {};
        event.sdk.name = event.sdk.name || sdkInfo.name;
        event.sdk.version = event.sdk.version || sdkInfo.version;
        event.sdk.integrations = __spread((event.sdk.integrations || []), (sdkInfo.integrations || []));
        event.sdk.packages = __spread((event.sdk.packages || []), (sdkInfo.packages || []));
        return event;
    }
    function sessionToSentryRequest(session, api) {
        var sdkInfo = getSdkMetadataForEnvelopeHeader(api);
        var envelopeHeaders = JSON.stringify(__assign(__assign({ sent_at: new Date().toISOString() }, (sdkInfo && { sdk: sdkInfo })), (api.forceEnvelope() && { dsn: api.getDsn().toString() })));
        var type = 'aggregates' in session ? 'sessions' : 'session';
        var itemHeaders = JSON.stringify({
            type: type,
        });
        return {
            body: envelopeHeaders + "\n" + itemHeaders + "\n" + JSON.stringify(session),
            type: type,
            url: api.getEnvelopeEndpointWithUrlEncodedAuth(),
        };
    }
    function eventToSentryRequest(event, api) {
        var sdkInfo = getSdkMetadataForEnvelopeHeader(api);
        var eventType = event.type || 'event';
        var useEnvelope = eventType === 'transaction' || api.forceEnvelope();
        var _a = event.debug_meta || {}, transactionSampling = _a.transactionSampling, metadata = __rest(_a, ["transactionSampling"]);
        var _b = transactionSampling || {}, samplingMethod = _b.method, sampleRate = _b.rate;
        if (Object.keys(metadata).length === 0) {
            delete event.debug_meta;
        }
        else {
            event.debug_meta = metadata;
        }
        var req = {
            body: JSON.stringify(sdkInfo ? enhanceEventWithSdkInfo(event, api.metadata.sdk) : event),
            type: eventType,
            url: useEnvelope ? api.getEnvelopeEndpointWithUrlEncodedAuth() : api.getStoreEndpointWithUrlEncodedAuth(),
        };
        if (useEnvelope) {
            var envelopeHeaders = JSON.stringify(__assign(__assign({ event_id: event.event_id, sent_at: new Date().toISOString() }, (sdkInfo && { sdk: sdkInfo })), (api.forceEnvelope() && { dsn: api.getDsn().toString() })));
            var itemHeaders = JSON.stringify({
                type: eventType,
                sample_rates: [{ id: samplingMethod, rate: sampleRate }],
            });
            var envelope = envelopeHeaders + "\n" + itemHeaders + "\n" + req.body;
            req.body = envelope;
        }
        return req;
    }

    
    function initAndBind(clientClass, options) {
        var _a;
        if (options.debug === true) {
            logger.enable();
        }
        var hub = getCurrentHub();
        (_a = hub.getScope()) === null || _a === void 0 ? void 0 : _a.update(options.initialScope);
        var client = new clientClass(options);
        hub.bindClient(client);
    }

    var SDK_VERSION = '6.14.0';

    var originalFunctionToString;
    var FunctionToString =  (function () {
        function FunctionToString() {
            this.name = FunctionToString.id;
        }
        FunctionToString.prototype.setupOnce = function () {
            originalFunctionToString = Function.prototype.toString;
            Function.prototype.toString = function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var context = this.__sentry_original__ || this;
                return originalFunctionToString.apply(context, args);
            };
        };
        FunctionToString.id = 'FunctionToString';
        return FunctionToString;
    }());

    
    var DEFAULT_IGNORE_ERRORS = [/^Script error\.?$/, /^Javascript error: Script error\.? on line 0$/];
    var InboundFilters =  (function () {
        function InboundFilters(_options) {
            if (_options === void 0) { _options = {}; }
            this._options = _options;
            this.name = InboundFilters.id;
        }
        InboundFilters.prototype.setupOnce = function () {
            addGlobalEventProcessor(function (event) {
                var hub = getCurrentHub();
                if (!hub) {
                    return event;
                }
                var self = hub.getIntegration(InboundFilters);
                if (self) {
                    var client = hub.getClient();
                    var clientOptions = client ? client.getOptions() : {};
                    var options = typeof self._mergeOptions === 'function' ? self._mergeOptions(clientOptions) : {};
                    if (typeof self._shouldDropEvent !== 'function') {
                        return event;
                    }
                    return self._shouldDropEvent(event, options) ? null : event;
                }
                return event;
            });
        };
        InboundFilters.prototype._shouldDropEvent = function (event, options) {
            if (this._isSentryError(event, options)) {
                logger.warn("Event dropped due to being internal Sentry Error.\nEvent: " + getEventDescription(event));
                return true;
            }
            if (this._isIgnoredError(event, options)) {
                logger.warn("Event dropped due to being matched by `ignoreErrors` option.\nEvent: " + getEventDescription(event));
                return true;
            }
            if (this._isDeniedUrl(event, options)) {
                logger.warn("Event dropped due to being matched by `denyUrls` option.\nEvent: " + getEventDescription(event) + ".\nUrl: " + this._getEventFilterUrl(event));
                return true;
            }
            if (!this._isAllowedUrl(event, options)) {
                logger.warn("Event dropped due to not being matched by `allowUrls` option.\nEvent: " + getEventDescription(event) + ".\nUrl: " + this._getEventFilterUrl(event));
                return true;
            }
            return false;
        };
        InboundFilters.prototype._isSentryError = function (event, options) {
            if (!options.ignoreInternal) {
                return false;
            }
            try {
                return ((event &&
                    event.exception &&
                    event.exception.values &&
                    event.exception.values[0] &&
                    event.exception.values[0].type === 'SentryError') ||
                    false);
            }
            catch (_oO) {
                return false;
            }
        };
        InboundFilters.prototype._isIgnoredError = function (event, options) {
            if (!options.ignoreErrors || !options.ignoreErrors.length) {
                return false;
            }
            return this._getPossibleEventMessages(event).some(function (message) {
                return options.ignoreErrors.some(function (pattern) { return isMatchingPattern(message, pattern); });
            });
        };
        InboundFilters.prototype._isDeniedUrl = function (event, options) {
            if (!options.denyUrls || !options.denyUrls.length) {
                return false;
            }
            var url = this._getEventFilterUrl(event);
            return !url ? false : options.denyUrls.some(function (pattern) { return isMatchingPattern(url, pattern); });
        };
        InboundFilters.prototype._isAllowedUrl = function (event, options) {
            if (!options.allowUrls || !options.allowUrls.length) {
                return true;
            }
            var url = this._getEventFilterUrl(event);
            return !url ? true : options.allowUrls.some(function (pattern) { return isMatchingPattern(url, pattern); });
        };
        InboundFilters.prototype._mergeOptions = function (clientOptions) {
            if (clientOptions === void 0) { clientOptions = {}; }
            return {
                allowUrls: __spread((this._options.whitelistUrls || []), (this._options.allowUrls || []), (clientOptions.whitelistUrls || []), (clientOptions.allowUrls || [])),
                denyUrls: __spread((this._options.blacklistUrls || []), (this._options.denyUrls || []), (clientOptions.blacklistUrls || []), (clientOptions.denyUrls || [])),
                ignoreErrors: __spread((this._options.ignoreErrors || []), (clientOptions.ignoreErrors || []), DEFAULT_IGNORE_ERRORS),
                ignoreInternal: typeof this._options.ignoreInternal !== 'undefined' ? this._options.ignoreInternal : true,
            };
        };
        InboundFilters.prototype._getPossibleEventMessages = function (event) {
            if (event.message) {
                return [event.message];
            }
            if (event.exception) {
                try {
                    var _a = (event.exception.values && event.exception.values[0]) || {}, _b = _a.type, type = _b === void 0 ? '' : _b, _c = _a.value, value = _c === void 0 ? '' : _c;
                    return ["" + value, type + ": " + value];
                }
                catch (oO) {
                    logger.error("Cannot extract message for event " + getEventDescription(event));
                    return [];
                }
            }
            return [];
        };
        InboundFilters.prototype._getLastValidUrl = function (frames) {
            if (frames === void 0) { frames = []; }
            var _a, _b;
            for (var i = frames.length - 1; i >= 0; i--) {
                var frame = frames[i];
                if (((_a = frame) === null || _a === void 0 ? void 0 : _a.filename) !== '<anonymous>' && ((_b = frame) === null || _b === void 0 ? void 0 : _b.filename) !== '[native code]') {
                    return frame.filename || null;
                }
            }
            return null;
        };
        InboundFilters.prototype._getEventFilterUrl = function (event) {
            try {
                if (event.stacktrace) {
                    var frames_1 = event.stacktrace.frames;
                    return this._getLastValidUrl(frames_1);
                }
                if (event.exception) {
                    var frames_2 = event.exception.values && event.exception.values[0].stacktrace && event.exception.values[0].stacktrace.frames;
                    return this._getLastValidUrl(frames_2);
                }
                return null;
            }
            catch (oO) {
                logger.error("Cannot extract url for event " + getEventDescription(event));
                return null;
            }
        };
        InboundFilters.id = 'InboundFilters';
        return InboundFilters;
    }());



    var CoreIntegrations = Object.freeze({
        __proto__: null,
        FunctionToString: FunctionToString,
        InboundFilters: InboundFilters
    });

    
    var UNKNOWN_FUNCTION = '?';
    var chrome = /^\s*at (?:(.*?) ?\()?((?:file|https?|blob|chrome-extension|address|native|eval|webpack|<anonymous>|[-a-z]+:|.*bundle|\/).*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i;
    var gecko = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)?((?:file|https?|blob|chrome|webpack|resource|moz-extension|capacitor).*?:\/.*?|\[native code\]|[^@]*(?:bundle|\d+\.js)|\/[\w\-. /=]+)(?::(\d+))?(?::(\d+))?\s*$/i;
    var winjs = /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:file|ms-appx|https?|webpack|blob):.*?):(\d+)(?::(\d+))?\)?\s*$/i;
    var geckoEval = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i;
    var chromeEval = /\((\S*)(?::(\d+))(?::(\d+))\)/;
    var reactMinifiedRegexp = /Minified React error #\d+;/i;
    function computeStackTrace(ex) {
        var stack = null;
        var popSize = 0;
        if (ex) {
            if (typeof ex.framesToPop === 'number') {
                popSize = ex.framesToPop;
            }
            else if (reactMinifiedRegexp.test(ex.message)) {
                popSize = 1;
            }
        }
        try {
            stack = computeStackTraceFromStacktraceProp(ex);
            if (stack) {
                return popFrames(stack, popSize);
            }
        }
        catch (e) {
        }
        try {
            stack = computeStackTraceFromStackProp(ex);
            if (stack) {
                return popFrames(stack, popSize);
            }
        }
        catch (e) {
        }
        return {
            message: extractMessage(ex),
            name: ex && ex.name,
            stack: [],
            failed: true,
        };
    }
    function computeStackTraceFromStackProp(ex) {
        var _a, _b;
        if (!ex || !ex.stack) {
            return null;
        }
        var stack = [];
        var lines = ex.stack.split('\n');
        var isEval;
        var submatch;
        var parts;
        var element;
        for (var i = 0; i < lines.length; ++i) {
            if ((parts = chrome.exec(lines[i]))) {
                var isNative = parts[2] && parts[2].indexOf('native') === 0; 
                isEval = parts[2] && parts[2].indexOf('eval') === 0; 
                if (isEval && (submatch = chromeEval.exec(parts[2]))) {
                    parts[2] = submatch[1]; 
                    parts[3] = submatch[2]; 
                    parts[4] = submatch[3]; 
                }
                var url = parts[2] && parts[2].indexOf('address at ') === 0 ? parts[2].substr('address at '.length) : parts[2];
                var func = parts[1] || UNKNOWN_FUNCTION;
                _a = __read(extractSafariExtensionDetails(func, url), 2), func = _a[0], url = _a[1];
                element = {
                    url: url,
                    func: func,
                    args: isNative ? [parts[2]] : [],
                    line: parts[3] ? +parts[3] : null,
                    column: parts[4] ? +parts[4] : null,
                };
            }
            else if ((parts = winjs.exec(lines[i]))) {
                element = {
                    url: parts[2],
                    func: parts[1] || UNKNOWN_FUNCTION,
                    args: [],
                    line: +parts[3],
                    column: parts[4] ? +parts[4] : null,
                };
            }
            else if ((parts = gecko.exec(lines[i]))) {
                isEval = parts[3] && parts[3].indexOf(' > eval') > -1;
                if (isEval && (submatch = geckoEval.exec(parts[3]))) {
                    parts[1] = parts[1] || "eval";
                    parts[3] = submatch[1];
                    parts[4] = submatch[2];
                    parts[5] = ''; 
                }
                else if (i === 0 && !parts[5] && ex.columnNumber !== void 0) {
                    stack[0].column = ex.columnNumber + 1;
                }
                var url = parts[3];
                var func = parts[1] || UNKNOWN_FUNCTION;
                _b = __read(extractSafariExtensionDetails(func, url), 2), func = _b[0], url = _b[1];
                element = {
                    url: url,
                    func: func,
                    args: parts[2] ? parts[2].split(',') : [],
                    line: parts[4] ? +parts[4] : null,
                    column: parts[5] ? +parts[5] : null,
                };
            }
            else {
                continue;
            }
            if (!element.func && element.line) {
                element.func = UNKNOWN_FUNCTION;
            }
            stack.push(element);
        }
        if (!stack.length) {
            return null;
        }
        return {
            message: extractMessage(ex),
            name: ex.name,
            stack: stack,
        };
    }
    function computeStackTraceFromStacktraceProp(ex) {
        if (!ex || !ex.stacktrace) {
            return null;
        }
        var stacktrace = ex.stacktrace;
        var opera10Regex = / line (\d+).*script (?:in )?(\S+)(?:: in function (\S+))?$/i;
        var opera11Regex = / line (\d+), column (\d+)\s*(?:in (?:<anonymous function: ([^>]+)>|([^)]+))\((.*)\))? in (.*):\s*$/i;
        var lines = stacktrace.split('\n');
        var stack = [];
        var parts;
        for (var line = 0; line < lines.length; line += 2) {
            var element = null;
            if ((parts = opera10Regex.exec(lines[line]))) {
                element = {
                    url: parts[2],
                    func: parts[3],
                    args: [],
                    line: +parts[1],
                    column: null,
                };
            }
            else if ((parts = opera11Regex.exec(lines[line]))) {
                element = {
                    url: parts[6],
                    func: parts[3] || parts[4],
                    args: parts[5] ? parts[5].split(',') : [],
                    line: +parts[1],
                    column: +parts[2],
                };
            }
            if (element) {
                if (!element.func && element.line) {
                    element.func = UNKNOWN_FUNCTION;
                }
                stack.push(element);
            }
        }
        if (!stack.length) {
            return null;
        }
        return {
            message: extractMessage(ex),
            name: ex.name,
            stack: stack,
        };
    }
    var extractSafariExtensionDetails = function (func, url) {
        var isSafariExtension = func.indexOf('safari-extension') !== -1;
        var isSafariWebExtension = func.indexOf('safari-web-extension') !== -1;
        return isSafariExtension || isSafariWebExtension
            ? [
                func.indexOf('@') !== -1 ? func.split('@')[0] : UNKNOWN_FUNCTION,
                isSafariExtension ? "safari-extension:" + url : "safari-web-extension:" + url,
            ]
            : [func, url];
    };
    function popFrames(stacktrace, popSize) {
        try {
            return __assign(__assign({}, stacktrace), { stack: stacktrace.stack.slice(popSize) });
        }
        catch (e) {
            return stacktrace;
        }
    }
    function extractMessage(ex) {
        var message = ex && ex.message;
        if (!message) {
            return 'No error message';
        }
        if (message.error && typeof message.error.message === 'string') {
            return message.error.message;
        }
        return message;
    }

    var STACKTRACE_LIMIT = 50;
    function exceptionFromStacktrace(stacktrace) {
        var frames = prepareFramesForEvent(stacktrace.stack);
        var exception = {
            type: stacktrace.name,
            value: stacktrace.message,
        };
        if (frames && frames.length) {
            exception.stacktrace = { frames: frames };
        }
        if (exception.type === undefined && exception.value === '') {
            exception.value = 'Unrecoverable error caught';
        }
        return exception;
    }
    function eventFromPlainObject(exception, syntheticException, rejection) {
        var event = {
            exception: {
                values: [
                    {
                        type: isEvent(exception) ? exception.constructor.name : rejection ? 'UnhandledRejection' : 'Error',
                        value: "Non-Error " + (rejection ? 'promise rejection' : 'exception') + " captured with keys: " + extractExceptionKeysForMessage(exception),
                    },
                ],
            },
            extra: {
                __serialized__: normalizeToSize(exception),
            },
        };
        if (syntheticException) {
            var stacktrace = computeStackTrace(syntheticException);
            var frames_1 = prepareFramesForEvent(stacktrace.stack);
            event.stacktrace = {
                frames: frames_1,
            };
        }
        return event;
    }
    function eventFromStacktrace(stacktrace) {
        var exception = exceptionFromStacktrace(stacktrace);
        return {
            exception: {
                values: [exception],
            },
        };
    }
    function prepareFramesForEvent(stack) {
        if (!stack || !stack.length) {
            return [];
        }
        var localStack = stack;
        var firstFrameFunction = localStack[0].func || '';
        var lastFrameFunction = localStack[localStack.length - 1].func || '';
        if (firstFrameFunction.indexOf('captureMessage') !== -1 || firstFrameFunction.indexOf('captureException') !== -1) {
            localStack = localStack.slice(1);
        }
        if (lastFrameFunction.indexOf('sentryWrapped') !== -1) {
            localStack = localStack.slice(0, -1);
        }
        return localStack
            .slice(0, STACKTRACE_LIMIT)
            .map(function (frame) { return ({
            colno: frame.column === null ? undefined : frame.column,
            filename: frame.url || localStack[0].url,
            function: frame.func || '?',
            in_app: true,
            lineno: frame.line === null ? undefined : frame.line,
        }); })
            .reverse();
    }

    
    function eventFromException(options, exception, hint) {
        var syntheticException = (hint && hint.syntheticException) || undefined;
        var event = eventFromUnknownInput(exception, syntheticException, {
            attachStacktrace: options.attachStacktrace,
        });
        addExceptionMechanism(event); 
        event.level = exports.Severity.Error;
        if (hint && hint.event_id) {
            event.event_id = hint.event_id;
        }
        return SyncPromise.resolve(event);
    }
    function eventFromMessage(options, message, level, hint) {
        if (level === void 0) { level = exports.Severity.Info; }
        var syntheticException = (hint && hint.syntheticException) || undefined;
        var event = eventFromString(message, syntheticException, {
            attachStacktrace: options.attachStacktrace,
        });
        event.level = level;
        if (hint && hint.event_id) {
            event.event_id = hint.event_id;
        }
        return SyncPromise.resolve(event);
    }
    function eventFromUnknownInput(exception, syntheticException, options) {
        if (options === void 0) { options = {}; }
        var event;
        if (isErrorEvent(exception) && exception.error) {
            var errorEvent = exception;
            exception = errorEvent.error;
            event = eventFromStacktrace(computeStackTrace(exception));
            return event;
        }
        if (isDOMError(exception) || isDOMException(exception)) {
            var domException = exception;
            var name_1 = domException.name || (isDOMError(domException) ? 'DOMError' : 'DOMException');
            var message = domException.message ? name_1 + ": " + domException.message : name_1;
            event = eventFromString(message, syntheticException, options);
            addExceptionTypeValue(event, message);
            if ('code' in domException) {
                event.tags = __assign(__assign({}, event.tags), { 'DOMException.code': "" + domException.code });
            }
            return event;
        }
        if (isError(exception)) {
            event = eventFromStacktrace(computeStackTrace(exception));
            return event;
        }
        if (isPlainObject(exception) || isEvent(exception)) {
            var objectException = exception;
            event = eventFromPlainObject(objectException, syntheticException, options.rejection);
            addExceptionMechanism(event, {
                synthetic: true,
            });
            return event;
        }
        event = eventFromString(exception, syntheticException, options);
        addExceptionTypeValue(event, "" + exception, undefined);
        addExceptionMechanism(event, {
            synthetic: true,
        });
        return event;
    }
    function eventFromString(input, syntheticException, options) {
        if (options === void 0) { options = {}; }
        var event = {
            message: input,
        };
        if (options.attachStacktrace && syntheticException) {
            var stacktrace = computeStackTrace(syntheticException);
            var frames_1 = prepareFramesForEvent(stacktrace.stack);
            event.stacktrace = {
                frames: frames_1,
            };
        }
        return event;
    }

    var global$3 = getGlobalObject();
    var cachedFetchImpl;
    function getNativeFetchImplementation() {
        var _a, _b;
        if (cachedFetchImpl) {
            return cachedFetchImpl;
        }
        if (isNativeFetch(global$3.fetch)) {
            return (cachedFetchImpl = global$3.fetch.bind(global$3));
        }
        var document = global$3.document;
        var fetchImpl = global$3.fetch;
        if (typeof ((_a = document) === null || _a === void 0 ? void 0 : _a.createElement) === "function") {
            try {
                var sandbox = document.createElement('iframe');
                sandbox.hidden = true;
                document.head.appendChild(sandbox);
                if ((_b = sandbox.contentWindow) === null || _b === void 0 ? void 0 : _b.fetch) {
                    fetchImpl = sandbox.contentWindow.fetch;
                }
                document.head.removeChild(sandbox);
            }
            catch (e) {
                logger.warn('Could not create sandbox iframe for pure fetch check, bailing to window.fetch: ', e);
            }
        }
        return (cachedFetchImpl = fetchImpl.bind(global$3));
    }
    function sendReport(url, body) {
        var isRealNavigator = Object.prototype.toString.call(global$3 && global$3.navigator) === '[object Navigator]';
        var hasSendBeacon = isRealNavigator && typeof global$3.navigator.sendBeacon === 'function';
        if (hasSendBeacon) {
            var sendBeacon = global$3.navigator.sendBeacon.bind(global$3.navigator);
            return sendBeacon(url, body);
        }
        if (supportsFetch()) {
            var fetch_1 = getNativeFetchImplementation();
            return forget(fetch_1(url, {
                body: body,
                method: 'POST',
                credentials: 'omit',
                keepalive: true,
            }));
        }
    }

    var CATEGORY_MAPPING = {
        event: 'error',
        transaction: 'transaction',
        session: 'session',
        attachment: 'attachment',
    };
    var global$4 = getGlobalObject();
    var BaseTransport =  (function () {
        function BaseTransport(options) {
            var _this = this;
            this.options = options;
            this._buffer = new PromiseBuffer(30);
            this._rateLimits = {};
            this._outcomes = {};
            this._api = new API(options.dsn, options._metadata, options.tunnel);
            this.url = this._api.getStoreEndpointWithUrlEncodedAuth();
            if (this.options.sendClientReports && global$4.document) {
                global$4.document.addEventListener('visibilitychange', function () {
                    if (global$4.document.visibilityState === 'hidden') {
                        _this._flushOutcomes();
                    }
                });
            }
        }
        BaseTransport.prototype.sendEvent = function (_) {
            throw new SentryError('Transport Class has to implement `sendEvent` method');
        };
        BaseTransport.prototype.close = function (timeout) {
            return this._buffer.drain(timeout);
        };
        BaseTransport.prototype.recordLostEvent = function (reason, category) {
            var _a;
            if (!this.options.sendClientReports) {
                return;
            }
            var key = CATEGORY_MAPPING[category] + ":" + reason;
            logger.log("Adding outcome: " + key);
            this._outcomes[key] = (_a = this._outcomes[key], (_a !== null && _a !== void 0 ? _a : 0)) + 1;
        };
        BaseTransport.prototype._flushOutcomes = function () {
            if (!this.options.sendClientReports) {
                return;
            }
            var outcomes = this._outcomes;
            this._outcomes = {};
            if (!Object.keys(outcomes).length) {
                logger.log('No outcomes to flush');
                return;
            }
            logger.log("Flushing outcomes:\n" + JSON.stringify(outcomes, null, 2));
            var url = this._api.getEnvelopeEndpointWithUrlEncodedAuth();
            var envelopeHeader = JSON.stringify(__assign({}, (this.options.tunnel && { dsn: this._api.getDsn().toString() })));
            var itemHeaders = JSON.stringify({
                type: 'client_report',
            });
            var item = JSON.stringify({
                timestamp: dateTimestampInSeconds(),
                discarded_events: Object.keys(outcomes).map(function (key) {
                    var _a = __read(key.split(':'), 2), category = _a[0], reason = _a[1];
                    return {
                        reason: reason,
                        category: category,
                        quantity: outcomes[key],
                    };
                }),
            });
            var envelope = envelopeHeader + "\n" + itemHeaders + "\n" + item;
            try {
                sendReport(url, envelope);
            }
            catch (e) {
                logger.error(e);
            }
        };
        BaseTransport.prototype._handleResponse = function (_a) {
            var requestType = _a.requestType, response = _a.response, headers = _a.headers, resolve = _a.resolve, reject = _a.reject;
            var status = exports.Status.fromHttpCode(response.status);
            var limited = this._handleRateLimit(headers);
            if (limited)
                logger.warn("Too many " + requestType + " requests, backing off until: " + this._disabledUntil(requestType));
            if (status === exports.Status.Success) {
                resolve({ status: status });
                return;
            }
            reject(response);
        };
        BaseTransport.prototype._disabledUntil = function (requestType) {
            var category = CATEGORY_MAPPING[requestType];
            return this._rateLimits[category] || this._rateLimits.all;
        };
        BaseTransport.prototype._isRateLimited = function (requestType) {
            return this._disabledUntil(requestType) > new Date(Date.now());
        };
        BaseTransport.prototype._handleRateLimit = function (headers) {
            var e_1, _a, e_2, _b;
            var now = Date.now();
            var rlHeader = headers['x-sentry-rate-limits'];
            var raHeader = headers['retry-after'];
            if (rlHeader) {
                try {
                    for (var _c = __values(rlHeader.trim().split(',')), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var limit = _d.value;
                        var parameters = limit.split(':', 2);
                        var headerDelay = parseInt(parameters[0], 10);
                        var delay = (!isNaN(headerDelay) ? headerDelay : 60) * 1000; 
                        try {
                            for (var _e = (e_2 = void 0, __values(parameters[1].split(';'))), _f = _e.next(); !_f.done; _f = _e.next()) {
                                var category = _f.value;
                                this._rateLimits[category || 'all'] = new Date(now + delay);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                return true;
            }
            else if (raHeader) {
                this._rateLimits.all = new Date(now + parseRetryAfterHeader(now, raHeader));
                return true;
            }
            return false;
        };
        return BaseTransport;
    }());

    
    var FetchTransport =  (function (_super) {
        __extends(FetchTransport, _super);
        function FetchTransport(options, fetchImpl) {
            if (fetchImpl === void 0) { fetchImpl = getNativeFetchImplementation(); }
            var _this = _super.call(this, options) || this;
            _this._fetch = fetchImpl;
            return _this;
        }
        FetchTransport.prototype.sendEvent = function (event) {
            return this._sendRequest(eventToSentryRequest(event, this._api), event);
        };
        FetchTransport.prototype.sendSession = function (session) {
            return this._sendRequest(sessionToSentryRequest(session, this._api), session);
        };
        FetchTransport.prototype._sendRequest = function (sentryRequest, originalPayload) {
            var _this = this;
            if (this._isRateLimited(sentryRequest.type)) {
                this.recordLostEvent(Outcome.RateLimitBackoff, sentryRequest.type);
                return Promise.reject({
                    event: originalPayload,
                    type: sentryRequest.type,
                    reason: "Transport for " + sentryRequest.type + " requests locked till " + this._disabledUntil(sentryRequest.type) + " due to too many requests.",
                    status: 429,
                });
            }
            var options = {
                body: sentryRequest.body,
                method: 'POST',
                referrerPolicy: (supportsReferrerPolicy() ? 'origin' : ''),
            };
            if (this.options.fetchParameters !== undefined) {
                Object.assign(options, this.options.fetchParameters);
            }
            if (this.options.headers !== undefined) {
                options.headers = this.options.headers;
            }
            return this._buffer
                .add(function () {
                return new SyncPromise(function (resolve, reject) {
                    void _this._fetch(sentryRequest.url, options)
                        .then(function (response) {
                        var headers = {
                            'x-sentry-rate-limits': response.headers.get('X-Sentry-Rate-Limits'),
                            'retry-after': response.headers.get('Retry-After'),
                        };
                        _this._handleResponse({
                            requestType: sentryRequest.type,
                            response: response,
                            headers: headers,
                            resolve: resolve,
                            reject: reject,
                        });
                    })
                        .catch(reject);
                });
            })
                .then(undefined, function (reason) {
                if (reason instanceof SentryError) {
                    _this.recordLostEvent(Outcome.QueueOverflow, sentryRequest.type);
                }
                else {
                    _this.recordLostEvent(Outcome.NetworkError, sentryRequest.type);
                }
                throw reason;
            });
        };
        return FetchTransport;
    }(BaseTransport));

    
    var XHRTransport =  (function (_super) {
        __extends(XHRTransport, _super);
        function XHRTransport() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        XHRTransport.prototype.sendEvent = function (event) {
            return this._sendRequest(eventToSentryRequest(event, this._api), event);
        };
        XHRTransport.prototype.sendSession = function (session) {
            return this._sendRequest(sessionToSentryRequest(session, this._api), session);
        };
        XHRTransport.prototype._sendRequest = function (sentryRequest, originalPayload) {
            var _this = this;
            if (this._isRateLimited(sentryRequest.type)) {
                this.recordLostEvent(Outcome.RateLimitBackoff, sentryRequest.type);
                return Promise.reject({
                    event: originalPayload,
                    type: sentryRequest.type,
                    reason: "Transport for " + sentryRequest.type + " requests locked till " + this._disabledUntil(sentryRequest.type) + " due to too many requests.",
                    status: 429,
                });
            }
            return this._buffer
                .add(function () {
                return new SyncPromise(function (resolve, reject) {
                    var request = new XMLHttpRequest();
                    request.onreadystatechange = function () {
                        if (request.readyState === 4) {
                            var headers = {
                                'x-sentry-rate-limits': request.getResponseHeader('X-Sentry-Rate-Limits'),
                                'retry-after': request.getResponseHeader('Retry-After'),
                            };
                            _this._handleResponse({ requestType: sentryRequest.type, response: request, headers: headers, resolve: resolve, reject: reject });
                        }
                    };
                    request.open('POST', sentryRequest.url);
                    for (var header in _this.options.headers) {
                        if (_this.options.headers.hasOwnProperty(header)) {
                            request.setRequestHeader(header, _this.options.headers[header]);
                        }
                    }
                    request.send(sentryRequest.body);
                });
            })
                .then(undefined, function (reason) {
                if (reason instanceof SentryError) {
                    _this.recordLostEvent(Outcome.QueueOverflow, sentryRequest.type);
                }
                else {
                    _this.recordLostEvent(Outcome.NetworkError, sentryRequest.type);
                }
                throw reason;
            });
        };
        return XHRTransport;
    }(BaseTransport));



    var index = Object.freeze({
        __proto__: null,
        BaseTransport: BaseTransport,
        FetchTransport: FetchTransport,
        XHRTransport: XHRTransport
    });

    
    var BrowserBackend =  (function (_super) {
        __extends(BrowserBackend, _super);
        function BrowserBackend() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        BrowserBackend.prototype.eventFromException = function (exception, hint) {
            return eventFromException(this._options, exception, hint);
        };
        BrowserBackend.prototype.eventFromMessage = function (message, level, hint) {
            if (level === void 0) { level = exports.Severity.Info; }
            return eventFromMessage(this._options, message, level, hint);
        };
        BrowserBackend.prototype._setupTransport = function () {
            if (!this._options.dsn) {
                return _super.prototype._setupTransport.call(this);
            }
            var transportOptions = __assign(__assign({}, this._options.transportOptions), { dsn: this._options.dsn, tunnel: this._options.tunnel, sendClientReports: this._options.sendClientReports, _metadata: this._options._metadata });
            if (this._options.transport) {
                return new this._options.transport(transportOptions);
            }
            if (supportsFetch()) {
                return new FetchTransport(transportOptions);
            }
            return new XHRTransport(transportOptions);
        };
        return BrowserBackend;
    }(BaseBackend));

    var global$5 = getGlobalObject();
    var ignoreOnError = 0;
    function shouldIgnoreOnError() {
        return ignoreOnError > 0;
    }
    function ignoreNextOnError() {
        ignoreOnError += 1;
        setTimeout(function () {
            ignoreOnError -= 1;
        });
    }
    function wrap(fn, options, before) {
        if (options === void 0) { options = {}; }
        if (typeof fn !== 'function') {
            return fn;
        }
        try {
            if (fn.__sentry__) {
                return fn;
            }
            if (fn.__sentry_wrapped__) {
                return fn.__sentry_wrapped__;
            }
        }
        catch (e) {
            return fn;
        }
        var sentryWrapped = function () {
            var args = Array.prototype.slice.call(arguments);
            try {
                if (before && typeof before === 'function') {
                    before.apply(this, arguments);
                }
                var wrappedArguments = args.map(function (arg) { return wrap(arg, options); });
                if (fn.handleEvent) {
                    return fn.handleEvent.apply(this, wrappedArguments);
                }
                return fn.apply(this, wrappedArguments);
            }
            catch (ex) {
                ignoreNextOnError();
                withScope(function (scope) {
                    scope.addEventProcessor(function (event) {
                        var processedEvent = __assign({}, event);
                        if (options.mechanism) {
                            addExceptionTypeValue(processedEvent, undefined, undefined);
                            addExceptionMechanism(processedEvent, options.mechanism);
                        }
                        processedEvent.extra = __assign(__assign({}, processedEvent.extra), { arguments: args });
                        return processedEvent;
                    });
                    captureException(ex);
                });
                throw ex;
            }
        };
        try {
            for (var property in fn) {
                if (Object.prototype.hasOwnProperty.call(fn, property)) {
                    sentryWrapped[property] = fn[property];
                }
            }
        }
        catch (_oO) { } 
        fn.prototype = fn.prototype || {};
        sentryWrapped.prototype = fn.prototype;
        Object.defineProperty(fn, '__sentry_wrapped__', {
            enumerable: false,
            value: sentryWrapped,
        });
        Object.defineProperties(sentryWrapped, {
            __sentry__: {
                enumerable: false,
                value: true,
            },
            __sentry_original__: {
                enumerable: false,
                value: fn,
            },
        });
        try {
            var descriptor = Object.getOwnPropertyDescriptor(sentryWrapped, 'name');
            if (descriptor.configurable) {
                Object.defineProperty(sentryWrapped, 'name', {
                    get: function () {
                        return fn.name;
                    },
                });
            }
        }
        catch (_oO) { }
        return sentryWrapped;
    }
    function injectReportDialog(options) {
        if (options === void 0) { options = {}; }
        if (!global$5.document) {
            return;
        }
        if (!options.eventId) {
            logger.error("Missing eventId option in showReportDialog call");
            return;
        }
        if (!options.dsn) {
            logger.error("Missing dsn option in showReportDialog call");
            return;
        }
        var script = global$5.document.createElement('script');
        script.async = true;
        script.src = new API(options.dsn).getReportDialogEndpoint(options);
        if (options.onLoad) {
            script.onload = options.onLoad;
        }
        var injectionPoint = global$5.document.head || global$5.document.body;
        if (injectionPoint) {
            injectionPoint.appendChild(script);
        }
    }

    
    var GlobalHandlers =  (function () {
        function GlobalHandlers(options) {
            this.name = GlobalHandlers.id;
            this._onErrorHandlerInstalled = false;
            this._onUnhandledRejectionHandlerInstalled = false;
            this._options = __assign({ onerror: true, onunhandledrejection: true }, options);
        }
        GlobalHandlers.prototype.setupOnce = function () {
            Error.stackTraceLimit = 50;
            if (this._options.onerror) {
                logger.log('Global Handler attached: onerror');
                this._installGlobalOnErrorHandler();
            }
            if (this._options.onunhandledrejection) {
                logger.log('Global Handler attached: onunhandledrejection');
                this._installGlobalOnUnhandledRejectionHandler();
            }
        };
        GlobalHandlers.prototype._installGlobalOnErrorHandler = function () {
            var _this = this;
            if (this._onErrorHandlerInstalled) {
                return;
            }
            addInstrumentationHandler({
                callback: function (data) {
                    var error = data.error;
                    var currentHub = getCurrentHub();
                    var hasIntegration = currentHub.getIntegration(GlobalHandlers);
                    var isFailedOwnDelivery = error && error.__sentry_own_request__ === true;
                    if (!hasIntegration || shouldIgnoreOnError() || isFailedOwnDelivery) {
                        return;
                    }
                    var client = currentHub.getClient();
                    var event = error === undefined && isString(data.msg)
                        ? _this._eventFromIncompleteOnError(data.msg, data.url, data.line, data.column)
                        : _this._enhanceEventWithInitialFrame(eventFromUnknownInput(error || data.msg, undefined, {
                            attachStacktrace: client && client.getOptions().attachStacktrace,
                            rejection: false,
                        }), data.url, data.line, data.column);
                    addExceptionMechanism(event, {
                        handled: false,
                        type: 'onerror',
                    });
                    currentHub.captureEvent(event, {
                        originalException: error,
                    });
                },
                type: 'error',
            });
            this._onErrorHandlerInstalled = true;
        };
        GlobalHandlers.prototype._installGlobalOnUnhandledRejectionHandler = function () {
            var _this = this;
            if (this._onUnhandledRejectionHandlerInstalled) {
                return;
            }
            addInstrumentationHandler({
                callback: function (e) {
                    var error = e;
                    try {
                        if ('reason' in e) {
                            error = e.reason;
                        }
                        else if ('detail' in e && 'reason' in e.detail) {
                            error = e.detail.reason;
                        }
                    }
                    catch (_oO) {
                    }
                    var currentHub = getCurrentHub();
                    var hasIntegration = currentHub.getIntegration(GlobalHandlers);
                    var isFailedOwnDelivery = error && error.__sentry_own_request__ === true;
                    if (!hasIntegration || shouldIgnoreOnError() || isFailedOwnDelivery) {
                        return true;
                    }
                    var client = currentHub.getClient();
                    var event = isPrimitive(error)
                        ? _this._eventFromRejectionWithPrimitive(error)
                        : eventFromUnknownInput(error, undefined, {
                            attachStacktrace: client && client.getOptions().attachStacktrace,
                            rejection: true,
                        });
                    event.level = exports.Severity.Error;
                    addExceptionMechanism(event, {
                        handled: false,
                        type: 'onunhandledrejection',
                    });
                    currentHub.captureEvent(event, {
                        originalException: error,
                    });
                    return;
                },
                type: 'unhandledrejection',
            });
            this._onUnhandledRejectionHandlerInstalled = true;
        };
        GlobalHandlers.prototype._eventFromIncompleteOnError = function (msg, url, line, column) {
            var ERROR_TYPES_RE = /^(?:[Uu]ncaught (?:exception: )?)?(?:((?:Eval|Internal|Range|Reference|Syntax|Type|URI|)Error): )?(.*)$/i;
            var message = isErrorEvent(msg) ? msg.message : msg;
            var name;
            var groups = message.match(ERROR_TYPES_RE);
            if (groups) {
                name = groups[1];
                message = groups[2];
            }
            var event = {
                exception: {
                    values: [
                        {
                            type: name || 'Error',
                            value: message,
                        },
                    ],
                },
            };
            return this._enhanceEventWithInitialFrame(event, url, line, column);
        };
        GlobalHandlers.prototype._eventFromRejectionWithPrimitive = function (reason) {
            return {
                exception: {
                    values: [
                        {
                            type: 'UnhandledRejection',
                            value: "Non-Error promise rejection captured with value: " + String(reason),
                        },
                    ],
                },
            };
        };
        GlobalHandlers.prototype._enhanceEventWithInitialFrame = function (event, url, line, column) {
            event.exception = event.exception || {};
            event.exception.values = event.exception.values || [];
            event.exception.values[0] = event.exception.values[0] || {};
            event.exception.values[0].stacktrace = event.exception.values[0].stacktrace || {};
            event.exception.values[0].stacktrace.frames = event.exception.values[0].stacktrace.frames || [];
            var colno = isNaN(parseInt(column, 10)) ? undefined : column;
            var lineno = isNaN(parseInt(line, 10)) ? undefined : line;
            var filename = isString(url) && url.length > 0 ? url : getLocationHref();
            if (event.exception.values[0].stacktrace.frames.length === 0) {
                event.exception.values[0].stacktrace.frames.push({
                    colno: colno,
                    filename: filename,
                    function: '?',
                    in_app: true,
                    lineno: lineno,
                });
            }
            return event;
        };
        GlobalHandlers.id = 'GlobalHandlers';
        return GlobalHandlers;
    }());

    var DEFAULT_EVENT_TARGET = [
        'EventTarget',
        'Window',
        'Node',
        'ApplicationCache',
        'AudioTrackList',
        'ChannelMergerNode',
        'CryptoOperation',
        'EventSource',
        'FileReader',
        'HTMLUnknownElement',
        'IDBDatabase',
        'IDBRequest',
        'IDBTransaction',
        'KeyOperation',
        'MediaController',
        'MessagePort',
        'ModalWindow',
        'Notification',
        'SVGElementInstance',
        'Screen',
        'TextTrack',
        'TextTrackCue',
        'TextTrackList',
        'WebSocket',
        'WebSocketWorker',
        'Worker',
        'XMLHttpRequest',
        'XMLHttpRequestEventTarget',
        'XMLHttpRequestUpload',
    ];
    var TryCatch =  (function () {
        function TryCatch(options) {
            this.name = TryCatch.id;
            this._options = __assign({ XMLHttpRequest: true, eventTarget: true, requestAnimationFrame: true, setInterval: true, setTimeout: true }, options);
        }
        TryCatch.prototype.setupOnce = function () {
            var global = getGlobalObject();
            if (this._options.setTimeout) {
                fill(global, 'setTimeout', this._wrapTimeFunction.bind(this));
            }
            if (this._options.setInterval) {
                fill(global, 'setInterval', this._wrapTimeFunction.bind(this));
            }
            if (this._options.requestAnimationFrame) {
                fill(global, 'requestAnimationFrame', this._wrapRAF.bind(this));
            }
            if (this._options.XMLHttpRequest && 'XMLHttpRequest' in global) {
                fill(XMLHttpRequest.prototype, 'send', this._wrapXHR.bind(this));
            }
            if (this._options.eventTarget) {
                var eventTarget = Array.isArray(this._options.eventTarget) ? this._options.eventTarget : DEFAULT_EVENT_TARGET;
                eventTarget.forEach(this._wrapEventTarget.bind(this));
            }
        };
        TryCatch.prototype._wrapTimeFunction = function (original) {
            return function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var originalCallback = args[0];
                args[0] = wrap(originalCallback, {
                    mechanism: {
                        data: { function: getFunctionName(original) },
                        handled: true,
                        type: 'instrument',
                    },
                });
                return original.apply(this, args);
            };
        };
        TryCatch.prototype._wrapRAF = function (original) {
            return function (callback) {
                return original.call(this, wrap(callback, {
                    mechanism: {
                        data: {
                            function: 'requestAnimationFrame',
                            handler: getFunctionName(original),
                        },
                        handled: true,
                        type: 'instrument',
                    },
                }));
            };
        };
        TryCatch.prototype._wrapEventTarget = function (target) {
            var global = getGlobalObject();
            var proto = global[target] && global[target].prototype;
            if (!proto || !proto.hasOwnProperty || !proto.hasOwnProperty('addEventListener')) {
                return;
            }
            fill(proto, 'addEventListener', function (original) {
                return function (eventName, fn, options) {
                    try {
                        if (typeof fn.handleEvent === 'function') {
                            fn.handleEvent = wrap(fn.handleEvent.bind(fn), {
                                mechanism: {
                                    data: {
                                        function: 'handleEvent',
                                        handler: getFunctionName(fn),
                                        target: target,
                                    },
                                    handled: true,
                                    type: 'instrument',
                                },
                            });
                        }
                    }
                    catch (err) {
                    }
                    return original.call(this, eventName, 
                    wrap(fn, {
                        mechanism: {
                            data: {
                                function: 'addEventListener',
                                handler: getFunctionName(fn),
                                target: target,
                            },
                            handled: true,
                            type: 'instrument',
                        },
                    }), options);
                };
            });
            fill(proto, 'removeEventListener', function (originalRemoveEventListener) {
                return function (eventName, fn, options) {
                    var _a;
                    var wrappedEventHandler = fn;
                    try {
                        var originalEventHandler = (_a = wrappedEventHandler) === null || _a === void 0 ? void 0 : _a.__sentry_wrapped__;
                        if (originalEventHandler) {
                            originalRemoveEventListener.call(this, eventName, originalEventHandler, options);
                        }
                    }
                    catch (e) {
                    }
                    return originalRemoveEventListener.call(this, eventName, wrappedEventHandler, options);
                };
            });
        };
        TryCatch.prototype._wrapXHR = function (originalSend) {
            return function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var xhr = this;
                var xmlHttpRequestProps = ['onload', 'onerror', 'onprogress', 'onreadystatechange'];
                xmlHttpRequestProps.forEach(function (prop) {
                    if (prop in xhr && typeof xhr[prop] === 'function') {
                        fill(xhr, prop, function (original) {
                            var wrapOptions = {
                                mechanism: {
                                    data: {
                                        function: prop,
                                        handler: getFunctionName(original),
                                    },
                                    handled: true,
                                    type: 'instrument',
                                },
                            };
                            if (original.__sentry_original__) {
                                wrapOptions.mechanism.data.handler = getFunctionName(original.__sentry_original__);
                            }
                            return wrap(original, wrapOptions);
                        });
                    }
                });
                return originalSend.apply(this, args);
            };
        };
        TryCatch.id = 'TryCatch';
        return TryCatch;
    }());

    
    var Breadcrumbs =  (function () {
        function Breadcrumbs(options) {
            this.name = Breadcrumbs.id;
            this._options = __assign({ console: true, dom: true, fetch: true, history: true, sentry: true, xhr: true }, options);
        }
        Breadcrumbs.prototype.addSentryBreadcrumb = function (event) {
            if (!this._options.sentry) {
                return;
            }
            getCurrentHub().addBreadcrumb({
                category: "sentry." + (event.type === 'transaction' ? 'transaction' : 'event'),
                event_id: event.event_id,
                level: event.level,
                message: getEventDescription(event),
            }, {
                event: event,
            });
        };
        Breadcrumbs.prototype.setupOnce = function () {
            var _this = this;
            if (this._options.console) {
                addInstrumentationHandler({
                    callback: function () {
                        var args = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            args[_i] = arguments[_i];
                        }
                        _this._consoleBreadcrumb.apply(_this, __spread(args));
                    },
                    type: 'console',
                });
            }
            if (this._options.dom) {
                addInstrumentationHandler({
                    callback: function () {
                        var args = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            args[_i] = arguments[_i];
                        }
                        _this._domBreadcrumb.apply(_this, __spread(args));
                    },
                    type: 'dom',
                });
            }
            if (this._options.xhr) {
                addInstrumentationHandler({
                    callback: function () {
                        var args = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            args[_i] = arguments[_i];
                        }
                        _this._xhrBreadcrumb.apply(_this, __spread(args));
                    },
                    type: 'xhr',
                });
            }
            if (this._options.fetch) {
                addInstrumentationHandler({
                    callback: function () {
                        var args = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            args[_i] = arguments[_i];
                        }
                        _this._fetchBreadcrumb.apply(_this, __spread(args));
                    },
                    type: 'fetch',
                });
            }
            if (this._options.history) {
                addInstrumentationHandler({
                    callback: function () {
                        var args = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            args[_i] = arguments[_i];
                        }
                        _this._historyBreadcrumb.apply(_this, __spread(args));
                    },
                    type: 'history',
                });
            }
        };
        Breadcrumbs.prototype._consoleBreadcrumb = function (handlerData) {
            var breadcrumb = {
                category: 'console',
                data: {
                    arguments: handlerData.args,
                    logger: 'console',
                },
                level: exports.Severity.fromString(handlerData.level),
                message: safeJoin(handlerData.args, ' '),
            };
            if (handlerData.level === 'assert') {
                if (handlerData.args[0] === false) {
                    breadcrumb.message = "Assertion failed: " + (safeJoin(handlerData.args.slice(1), ' ') || 'console.assert');
                    breadcrumb.data.arguments = handlerData.args.slice(1);
                }
                else {
                    return;
                }
            }
            getCurrentHub().addBreadcrumb(breadcrumb, {
                input: handlerData.args,
                level: handlerData.level,
            });
        };
        Breadcrumbs.prototype._domBreadcrumb = function (handlerData) {
            var target;
            var keyAttrs = typeof this._options.dom === 'object' ? this._options.dom.serializeAttribute : undefined;
            if (typeof keyAttrs === 'string') {
                keyAttrs = [keyAttrs];
            }
            try {
                target = handlerData.event.target
                    ? htmlTreeAsString(handlerData.event.target, keyAttrs)
                    : htmlTreeAsString(handlerData.event, keyAttrs);
            }
            catch (e) {
                target = '<unknown>';
            }
            if (target.length === 0) {
                return;
            }
            getCurrentHub().addBreadcrumb({
                category: "ui." + handlerData.name,
                message: target,
            }, {
                event: handlerData.event,
                name: handlerData.name,
                global: handlerData.global,
            });
        };
        Breadcrumbs.prototype._xhrBreadcrumb = function (handlerData) {
            if (handlerData.endTimestamp) {
                if (handlerData.xhr.__sentry_own_request__) {
                    return;
                }
                var _a = handlerData.xhr.__sentry_xhr__ || {}, method = _a.method, url = _a.url, status_code = _a.status_code, body = _a.body;
                getCurrentHub().addBreadcrumb({
                    category: 'xhr',
                    data: {
                        method: method,
                        url: url,
                        status_code: status_code,
                    },
                    type: 'http',
                }, {
                    xhr: handlerData.xhr,
                    input: body,
                });
                return;
            }
        };
        Breadcrumbs.prototype._fetchBreadcrumb = function (handlerData) {
            if (!handlerData.endTimestamp) {
                return;
            }
            if (handlerData.fetchData.url.match(/sentry_key/) && handlerData.fetchData.method === 'POST') {
                return;
            }
            if (handlerData.error) {
                getCurrentHub().addBreadcrumb({
                    category: 'fetch',
                    data: handlerData.fetchData,
                    level: exports.Severity.Error,
                    type: 'http',
                }, {
                    data: handlerData.error,
                    input: handlerData.args,
                });
            }
            else {
                getCurrentHub().addBreadcrumb({
                    category: 'fetch',
                    data: __assign(__assign({}, handlerData.fetchData), { status_code: handlerData.response.status }),
                    type: 'http',
                }, {
                    input: handlerData.args,
                    response: handlerData.response,
                });
            }
        };
        Breadcrumbs.prototype._historyBreadcrumb = function (handlerData) {
            var global = getGlobalObject();
            var from = handlerData.from;
            var to = handlerData.to;
            var parsedLoc = parseUrl(global.location.href);
            var parsedFrom = parseUrl(from);
            var parsedTo = parseUrl(to);
            if (!parsedFrom.path) {
                parsedFrom = parsedLoc;
            }
            if (parsedLoc.protocol === parsedTo.protocol && parsedLoc.host === parsedTo.host) {
                to = parsedTo.relative;
            }
            if (parsedLoc.protocol === parsedFrom.protocol && parsedLoc.host === parsedFrom.host) {
                from = parsedFrom.relative;
            }
            getCurrentHub().addBreadcrumb({
                category: 'navigation',
                data: {
                    from: from,
                    to: to,
                },
            });
        };
        Breadcrumbs.id = 'Breadcrumbs';
        return Breadcrumbs;
    }());

    var DEFAULT_KEY = 'cause';
    var DEFAULT_LIMIT = 5;
    var LinkedErrors =  (function () {
        function LinkedErrors(options) {
            if (options === void 0) { options = {}; }
            this.name = LinkedErrors.id;
            this._key = options.key || DEFAULT_KEY;
            this._limit = options.limit || DEFAULT_LIMIT;
        }
        LinkedErrors.prototype.setupOnce = function () {
            addGlobalEventProcessor(function (event, hint) {
                var self = getCurrentHub().getIntegration(LinkedErrors);
                if (self) {
                    var handler = self._handler && self._handler.bind(self);
                    return typeof handler === 'function' ? handler(event, hint) : event;
                }
                return event;
            });
        };
        LinkedErrors.prototype._handler = function (event, hint) {
            if (!event.exception || !event.exception.values || !hint || !isInstanceOf(hint.originalException, Error)) {
                return event;
            }
            var linkedErrors = this._walkErrorTree(hint.originalException, this._key);
            event.exception.values = __spread(linkedErrors, event.exception.values);
            return event;
        };
        LinkedErrors.prototype._walkErrorTree = function (error, key, stack) {
            if (stack === void 0) { stack = []; }
            if (!isInstanceOf(error[key], Error) || stack.length + 1 >= this._limit) {
                return stack;
            }
            var stacktrace = computeStackTrace(error[key]);
            var exception = exceptionFromStacktrace(stacktrace);
            return this._walkErrorTree(error[key], key, __spread([exception], stack));
        };
        LinkedErrors.id = 'LinkedErrors';
        return LinkedErrors;
    }());

    var global$6 = getGlobalObject();
    var UserAgent =  (function () {
        function UserAgent() {
            this.name = UserAgent.id;
        }
        UserAgent.prototype.setupOnce = function () {
            addGlobalEventProcessor(function (event) {
                var _a, _b, _c;
                if (getCurrentHub().getIntegration(UserAgent)) {
                    if (!global$6.navigator && !global$6.location && !global$6.document) {
                        return event;
                    }
                    var url = ((_a = event.request) === null || _a === void 0 ? void 0 : _a.url) || ((_b = global$6.location) === null || _b === void 0 ? void 0 : _b.href);
                    var referrer = (global$6.document || {}).referrer;
                    var userAgent = (global$6.navigator || {}).userAgent;
                    var headers = __assign(__assign(__assign({}, (_c = event.request) === null || _c === void 0 ? void 0 : _c.headers), (referrer && { Referer: referrer })), (userAgent && { 'User-Agent': userAgent }));
                    var request = __assign(__assign({}, (url && { url: url })), { headers: headers });
                    return __assign(__assign({}, event), { request: request });
                }
                return event;
            });
        };
        UserAgent.id = 'UserAgent';
        return UserAgent;
    }());

    
    var Dedupe =  (function () {
        function Dedupe() {
            this.name = Dedupe.id;
        }
        Dedupe.prototype.setupOnce = function (addGlobalEventProcessor, getCurrentHub) {
            addGlobalEventProcessor(function (currentEvent) {
                var self = getCurrentHub().getIntegration(Dedupe);
                if (self) {
                    try {
                        if (self._shouldDropEvent(currentEvent, self._previousEvent)) {
                            logger.warn("Event dropped due to being a duplicate of previously captured event.");
                            return null;
                        }
                    }
                    catch (_oO) {
                        return (self._previousEvent = currentEvent);
                    }
                    return (self._previousEvent = currentEvent);
                }
                return currentEvent;
            });
        };
        Dedupe.prototype._shouldDropEvent = function (currentEvent, previousEvent) {
            if (!previousEvent) {
                return false;
            }
            if (this._isSameMessageEvent(currentEvent, previousEvent)) {
                return true;
            }
            if (this._isSameExceptionEvent(currentEvent, previousEvent)) {
                return true;
            }
            return false;
        };
        Dedupe.prototype._isSameMessageEvent = function (currentEvent, previousEvent) {
            var currentMessage = currentEvent.message;
            var previousMessage = previousEvent.message;
            if (!currentMessage && !previousMessage) {
                return false;
            }
            if ((currentMessage && !previousMessage) || (!currentMessage && previousMessage)) {
                return false;
            }
            if (currentMessage !== previousMessage) {
                return false;
            }
            if (!this._isSameFingerprint(currentEvent, previousEvent)) {
                return false;
            }
            if (!this._isSameStacktrace(currentEvent, previousEvent)) {
                return false;
            }
            return true;
        };
        Dedupe.prototype._getFramesFromEvent = function (event) {
            var exception = event.exception;
            if (exception) {
                try {
                    return exception.values[0].stacktrace.frames;
                }
                catch (_oO) {
                    return undefined;
                }
            }
            else if (event.stacktrace) {
                return event.stacktrace.frames;
            }
            return undefined;
        };
        Dedupe.prototype._isSameStacktrace = function (currentEvent, previousEvent) {
            var currentFrames = this._getFramesFromEvent(currentEvent);
            var previousFrames = this._getFramesFromEvent(previousEvent);
            if (!currentFrames && !previousFrames) {
                return true;
            }
            if ((currentFrames && !previousFrames) || (!currentFrames && previousFrames)) {
                return false;
            }
            currentFrames = currentFrames;
            previousFrames = previousFrames;
            if (previousFrames.length !== currentFrames.length) {
                return false;
            }
            for (var i = 0; i < previousFrames.length; i++) {
                var frameA = previousFrames[i];
                var frameB = currentFrames[i];
                if (frameA.filename !== frameB.filename ||
                    frameA.lineno !== frameB.lineno ||
                    frameA.colno !== frameB.colno ||
                    frameA.function !== frameB.function) {
                    return false;
                }
            }
            return true;
        };
        Dedupe.prototype._getExceptionFromEvent = function (event) {
            return event.exception && event.exception.values && event.exception.values[0];
        };
        Dedupe.prototype._isSameExceptionEvent = function (currentEvent, previousEvent) {
            var previousException = this._getExceptionFromEvent(previousEvent);
            var currentException = this._getExceptionFromEvent(currentEvent);
            if (!previousException || !currentException) {
                return false;
            }
            if (previousException.type !== currentException.type || previousException.value !== currentException.value) {
                return false;
            }
            if (!this._isSameFingerprint(currentEvent, previousEvent)) {
                return false;
            }
            if (!this._isSameStacktrace(currentEvent, previousEvent)) {
                return false;
            }
            return true;
        };
        Dedupe.prototype._isSameFingerprint = function (currentEvent, previousEvent) {
            var currentFingerprint = currentEvent.fingerprint;
            var previousFingerprint = previousEvent.fingerprint;
            if (!currentFingerprint && !previousFingerprint) {
                return true;
            }
            if ((currentFingerprint && !previousFingerprint) || (!currentFingerprint && previousFingerprint)) {
                return false;
            }
            currentFingerprint = currentFingerprint;
            previousFingerprint = previousFingerprint;
            try {
                return !!(currentFingerprint.join('') === previousFingerprint.join(''));
            }
            catch (_oO) {
                return false;
            }
        };
        Dedupe.id = 'Dedupe';
        return Dedupe;
    }());



    var BrowserIntegrations = Object.freeze({
        __proto__: null,
        GlobalHandlers: GlobalHandlers,
        TryCatch: TryCatch,
        Breadcrumbs: Breadcrumbs,
        LinkedErrors: LinkedErrors,
        UserAgent: UserAgent,
        Dedupe: Dedupe
    });

    
    var BrowserClient =  (function (_super) {
        __extends(BrowserClient, _super);
        function BrowserClient(options) {
            if (options === void 0) { options = {}; }
            var _this = this;
            options._metadata = options._metadata || {};
            options._metadata.sdk = options._metadata.sdk || {
                name: 'sentry.javascript.browser',
                packages: [
                    {
                        name: 'npm:@sentry/browser',
                        version: SDK_VERSION,
                    },
                ],
                version: SDK_VERSION,
            };
            _this = _super.call(this, BrowserBackend, options) || this;
            return _this;
        }
        BrowserClient.prototype.showReportDialog = function (options) {
            if (options === void 0) { options = {}; }
            var document = getGlobalObject().document;
            if (!document) {
                return;
            }
            if (!this._isEnabled()) {
                logger.error('Trying to call showReportDialog with Sentry Client disabled');
                return;
            }
            injectReportDialog(__assign(__assign({}, options), { dsn: options.dsn || this.getDsn() }));
        };
        BrowserClient.prototype._prepareEvent = function (event, scope, hint) {
            event.platform = event.platform || 'javascript';
            return _super.prototype._prepareEvent.call(this, event, scope, hint);
        };
        BrowserClient.prototype._sendEvent = function (event) {
            var integration = this.getIntegration(Breadcrumbs);
            if (integration) {
                integration.addSentryBreadcrumb(event);
            }
            _super.prototype._sendEvent.call(this, event);
        };
        return BrowserClient;
    }(BaseClient));

    var defaultIntegrations = [
        new InboundFilters(),
        new FunctionToString(),
        new TryCatch(),
        new Breadcrumbs(),
        new GlobalHandlers(),
        new LinkedErrors(),
        new Dedupe(),
        new UserAgent(),
    ];
    function init(options) {
        if (options === void 0) { options = {}; }
        if (options.defaultIntegrations === undefined) {
            options.defaultIntegrations = defaultIntegrations;
        }
        if (options.release === undefined) {
            var window_1 = getGlobalObject();
            if (window_1.SENTRY_RELEASE && window_1.SENTRY_RELEASE.id) {
                options.release = window_1.SENTRY_RELEASE.id;
            }
        }
        if (options.autoSessionTracking === undefined) {
            options.autoSessionTracking = true;
        }
        if (options.sendClientReports === undefined) {
            options.sendClientReports = true;
        }
        initAndBind(BrowserClient, options);
        if (options.autoSessionTracking) {
            startSessionTracking();
        }
    }
    function showReportDialog(options) {
        if (options === void 0) { options = {}; }
        var hub = getCurrentHub();
        var scope = hub.getScope();
        if (scope) {
            options.user = __assign(__assign({}, scope.getUser()), options.user);
        }
        if (!options.eventId) {
            options.eventId = hub.lastEventId();
        }
        var client = hub.getClient();
        if (client) {
            client.showReportDialog(options);
        }
    }
    function lastEventId() {
        return getCurrentHub().lastEventId();
    }
    function forceLoad() {
    }
    function onLoad(callback) {
        callback();
    }
    function flush(timeout) {
        var client = getCurrentHub().getClient();
        if (client) {
            return client.flush(timeout);
        }
        logger.warn('Cannot flush events. No client defined.');
        return SyncPromise.resolve(false);
    }
    function close(timeout) {
        var client = getCurrentHub().getClient();
        if (client) {
            return client.close(timeout);
        }
        logger.warn('Cannot flush events and disable SDK. No client defined.');
        return SyncPromise.resolve(false);
    }
    function wrap$1(fn) {
        return wrap(fn)();
    }
    function startSessionTracking() {
        var window = getGlobalObject();
        var document = window.document;
        if (typeof document === 'undefined') {
            logger.warn('Session tracking in non-browser environment with @sentry/browser is not supported.');
            return;
        }
        var hub = getCurrentHub();
        if (typeof hub.startSession !== 'function' || typeof hub.captureSession !== 'function') {
            return;
        }
        hub.startSession({ ignoreDuration: true });
        hub.captureSession();
        addInstrumentationHandler({
            callback: function (_a) {
                var from = _a.from, to = _a.to;
                if (from === undefined || from === to) {
                    return;
                }
                hub.startSession({ ignoreDuration: true });
                hub.captureSession();
            },
            type: 'history',
        });
    }

    
    var SDK_NAME = 'sentry.javascript.browser';

    var windowIntegrations = {};
    var _window = getGlobalObject();
    if (_window.Sentry && _window.Sentry.Integrations) {
        windowIntegrations = _window.Sentry.Integrations;
    }
    var INTEGRATIONS = __assign(__assign(__assign({}, windowIntegrations), CoreIntegrations), BrowserIntegrations);

    exports.BrowserClient = BrowserClient;
    exports.Hub = Hub;
    exports.Integrations = INTEGRATIONS;
    exports.SDK_NAME = SDK_NAME;
    exports.SDK_VERSION = SDK_VERSION;
    exports.Scope = Scope;
    exports.Transports = index;
    exports.addBreadcrumb = addBreadcrumb;
    exports.addGlobalEventProcessor = addGlobalEventProcessor;
    exports.captureEvent = captureEvent;
    exports.captureException = captureException;
    exports.captureMessage = captureMessage;
    exports.close = close;
    exports.configureScope = configureScope;
    exports.defaultIntegrations = defaultIntegrations;
    exports.eventFromException = eventFromException;
    exports.eventFromMessage = eventFromMessage;
    exports.flush = flush;
    exports.forceLoad = forceLoad;
    exports.getCurrentHub = getCurrentHub;
    exports.getHubFromCarrier = getHubFromCarrier;
    exports.init = init;
    exports.injectReportDialog = injectReportDialog;
    exports.lastEventId = lastEventId;
    exports.makeMain = makeMain;
    exports.onLoad = onLoad;
    exports.setContext = setContext;
    exports.setExtra = setExtra;
    exports.setExtras = setExtras;
    exports.setTag = setTag;
    exports.setTags = setTags;
    exports.setUser = setUser;
    exports.showReportDialog = showReportDialog;
    exports.startTransaction = startTransaction;
    exports.withScope = withScope;
    exports.wrap = wrap$1;

    return exports;

}({}));
