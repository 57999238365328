(function (window, angular, undefined) {
  'use strict';


  angular.module('KeyCodes.Service', [])

  .factory('$keyCodes', function () {
    return {

      BACKSPACE: 8,
      TAB: 9,
      CLEAR: 12,
      ENTER: 13,
      ESC: 27,
      SPACE: 32,

      PAGEUP: 33,
      PAGEDOWN: 34,
      END: 35,
      HOME: 36,

      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      DOWN: 40,

      INSERT: 45,
      DELETE: 46,

      SHIFT: 16,
      CTRL: 17,
      ALT: 18,
      BREAK: 19,
      CAPSLOCK: 20,
      CMDLEFT: 91,
      CMDRIGHT: 93,

      COMMA: 188,
      COLON: 190,
      DASH: 189,
      EQUALS: 187,
      QUOTE: 222,
      BACK_TICK: 192,
      AT: 50,

      COMBINED: 229,

      NUMERIC: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57],

      ALPHA: [65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 
      78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90],

      CONTROL: [8, 9, 12, 13, 27, 
      35, 36, 45, 46, 
      37, 38, 39, 40],

      isControl: function isControl(event, allowed) {
        var codes = this.mergeCodes(this.CONTROL, allowed);
        return codes.indexOf(event.keyCode) >= 0 && event.charCode === 0;
      },


      isNumeric: function isNumeric(event, allowed) {
        var codes = this.mergeCodes(this.NUMERIC, allowed);
        return codes.indexOf(event.which) >= 0;
      },


      isAlpha: function isAlpha(event, allowed) {
        var codes = this.mergeCodes(this.ALPHA, allowed);
        return codes.indexOf(event.which) >= 0;
      },


      isAlphaNumeric: function isAlphaNumeric(event, allowed) {
        var codes = this.mergeCodes(this.ALPHA, this.NUMERIC, allowed);
        return codes.indexOf(event.which) >= 0;
      },


      mergeCodes: function mergeCodes() {
        var codes = [];
        for (var i = 0; i < arguments.length; i++) {
          if (arguments[i]) {
            var merge = arguments[i];
            if (!angular.isArray(merge)) {
              merge = [merge];
            }
            codes = codes.concat(merge);
          }
        }
        return codes;
      }
    };
  });
})(window, window.angular);