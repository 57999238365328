(function (window, angular, undefined) {
  'use strict';

  angular.module('AppendAnimated.Service', [])

  .factory('$appendAnimated', ['$animate', function ($animate) {
    return function (child, parent) {
      var children = parent.children();
      if (children.length > 0) {
        return $animate.enter(child, parent, children[children.length - 1]);
      }
      return $animate.enter(child, parent);
    };
  }]);
})(window, window.angular);
(function (window, angular, undefined) {
  'use strict';


  angular.module('ModalOverlay.Service', ['AppendAnimated.Service'])

  .factory('$modalOverlay', ['$animate', '$document', '$appendAnimated', function ($animate, $document, $appendAnimated) {

    var overlayElement = void 0;
    var bodyElement = $document.find('body').eq(0);

    return {

      show: function show(overlayClass) {

        if (overlayElement) {
          return;
        }

        overlayElement = angular.element('<div></div>').attr({
          class: overlayClass
        });

        return $appendAnimated(overlayElement, bodyElement);
      },


      hide: function hide() {
        if (overlayElement) {
          $animate.leave(overlayElement);
          overlayElement = null;
        }
      },


      setIndex: function setIndex(baseIndex, numModals) {
        if (overlayElement) {
          var zIndex = baseIndex + 2 * (numModals - 1);
          overlayElement[0].style.zIndex = zIndex;
        }
      }
    };
  }]);
})(window, window.angular);
(function (window, angular, undefined) {
  'use strict';


  angular.module('ModalStack.Service', [])

  .factory('$modalStack', function () {

    var stack = [];

    return {

      get: function get() {
        return stack.map(function (instance) {
          return instance;
        });
      },


      isEmpty: function isEmpty() {
        return stack.length === 0;
      },


      numOpen: function numOpen() {
        return stack.length;
      },


      isOpen: function isOpen(name) {

        if (!name) {
          return false;
        }

        if (!Array.isArray(name)) {
          name = [name];
        }

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = stack[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var modal = _step.value;

            if (name.includes(modal.name)) {
              return true;
            }
          }

        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return false;
      },


      isLast: function isLast(name) {

        if (!name || stack.length === 0) {
          return false;
        }

        var last = stack[stack.length - 1];
        return last.name === name;
      },


      add: function add(modalInstance) {
        stack.push(modalInstance);
      },


      remove: function remove(modalInstance) {
        var index = stack.indexOf(modalInstance);
        if (index > -1) {
          stack.splice(index, 1);
        }
      }
    };
  });
})(window, window.angular);
var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj; };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

(function (window, angular, undefined) {
  'use strict';


  angular.module('Modal.Service', ['ModalStack.Service', 'ModalOverlay.Service', 'AppendAnimated.Service'])

  .provider('$modal', function $modalProvider() {

    var REASON_CANCEL = 'cancel';
    var REASON_CLOSE_PREVENTED = 'close prevented';

    this.defaults = {
      closeOnEsc: true,
      closeOnClick: true,
      template: null,
      templateUrl: null,
      scope: null,
      controller: null,
      controllerAs: '$ctrl',
      resolve: {},
      locals: null,
      appendTo: null,
      overlay: true,
      wrapperClass: 'modal-wrapper ModalWrapper',
      overlayClass: 'modal-overlay ModalOverlay',
      onBeforeClose: null,
      once: false,
      rejectOnDismissal: false
    };

    this.configs = {};

    this.setDefaults = function (defaults) {
      this.defaults = angular.extend(this.defaults, defaults || {});
      return this;
    };

    this.modal = function (name, config) {
      var _this = this;

      if (name && (typeof name === 'undefined' ? 'undefined' : _typeof(name)) === 'object') {
        angular.forEach(name, function (config, name) {
          _this.modal(name, config);
        }, this);
        return;
      }

      this.configs[name] = angular.extend({}, this.defaults, config || {});
      return this;
    };

    this.$get = ['$rootScope', '$q', '$templateRequest', '$injector', '$controller', '$compile', '$document', '$animate', '$modalStack', '$modalOverlay', '$appendAnimated', function ($rootScope, $q, $templateRequest, $injector, $controller, $compile, $document, $animate, $modalStack, $modalOverlay, $appendAnimated) {

      var baseIndex = 10000;
      var defaults = this.defaults;
      var configs = this.configs;

      var bodyElement = $document.find('body').eq(0);

      function getTemplatePromise(template, templateUrl) {
        if (template) {
          return $q.when(template);
        }
        return $templateRequest(templateUrl, true);
      }

      function getResolvePromises(resolves) {
        var promises = [];
        angular.forEach(resolves, function (item) {
          if (angular.isFunction(item) || angular.isArray(item)) {
            promises.push($q.when($injector.invoke(item)));
          } else if (angular.isString(item)) {
            promises.push($q.when($injector.get(item)));
          } else {
            promises.push($q.when(item));
          }
        });
        return promises;
      }

      function openModal(modalInstance) {

        var modal = modalInstance.$$modal;
        var numModals = $modalStack.numOpen() + 1;

        modal.element.attr({ class: modal.wrapperClass }).html(modal.content);
        modal.element = $compile(modal.element)(modal.scope);
        modal.element[0].style.zIndex = baseIndex + 2 * numModals - 1;

        if (modal.closeOnClick) {
          modal.element.on('click', function (event) {
            if (event.target === event.currentTarget) {
              event.preventDefault();
              event.stopPropagation();
              $rootScope.$apply(function () {
                closeModal(modalInstance, REASON_CANCEL, true);
              });
            }
          });
        }

        $modalStack.add(modalInstance);
        if (modal.showOverlay) {
          $modalOverlay.show(modal.overlayClass);
          $modalOverlay.setIndex(baseIndex, numModals);
        }

        if (modal.controller && modal.controller.$onInit) {
          modal.controller.$onInit.call(modal.controller);
        }

        modal.openedDeferred.resolve(true);

        return $appendAnimated(modal.element, modal.parent).then(function () {

          if (modal.controller && modal.controller.$postLink) {
            modal.controller.$postLink.call(modal.controller);
          }
        });
      }

      function confirmCloseModal(modalInstance, result, wasDismissed) {

        var modal = modalInstance.$$modal;
        var numModals = $modalStack.numOpen() - 1;

        if (!modal.element) {
          return $q.when(true);
        }

        if (wasDismissed) {
          modal.closedDeferred.resolve(result);
          if (modal.rejectOnDismissal) {
            modal.resultDeferred.reject(result);
          }
        } else {
          modal.resultDeferred.resolve(result);
        }

        $modalStack.remove(modalInstance);
        if ($modalStack.isEmpty()) {
          $modalOverlay.hide();
        } else {
          $modalOverlay.setIndex(baseIndex, numModals);
        }

        return $animate.leave(modal.element).then(function () {

          if (modal.controller && modal.controller.$onDestroy) {
            modal.controller.$onDestroy.call(modal.controller);
          }

          if (modal.scope) {
            modal.scope.$destroy();
            modal.scope = null;
          }

          modal.element = null;

          $document[0].removeEventListener('keydown', modal.broadcastEnter);
          if (modal.closeOnEsc) {
            $document[0].removeEventListener('keydown', modal.closeOnEsc);
            modal.closeOnEsc = null;
          }
        });
      }

      function closeModal(modalInstance, result, wasDismissed) {

        var modal = modalInstance.$$modal;

        if (!modal.element) {
          return $q.when(true);
        }

        if (typeof modal.onBeforeClose === 'function') {

          var outcome = modal.onBeforeClose(modalInstance, result, wasDismissed);

          if (outcome && typeof outcome.then === 'function') {
            return outcome.then(function () {
              return confirmCloseModal(modalInstance, result, wasDismissed);
            }).catch(function (reason) {
              return $q.reject(reason || REASON_CLOSE_PREVENTED);
            });
          }

          if (typeof outcome !== 'undefined' && outcome !== true) {
            return $q.reject(outcome || REASON_CLOSE_PREVENTED);
          }
        }

        return confirmCloseModal(modalInstance, result, wasDismissed);
      }

      var Modal = {

        open: function open(name, options, closeOthers) {

          if (typeof name !== 'string') {
            options = name || {};
            name = '';
          }

          if (name && typeof configs[name] !== 'undefined') {
            options = angular.extend({}, configs[name], options || {});
          } else if (name) {
            throw new Error('String given as options, but config with name ' + name + ' was not predefined');
          }

          if (name && options.once && $modalStack.isOpen(name)) {
            return null;
          }

          options = angular.extend({}, defaults, options || {});
          options.resolve = options.resolve || {};
          options.appendTo = options.appendTo || bodyElement;

          if (!options.template && !options.templateUrl) {
            throw new Error('One of template or templateUrl options is required');
          }

          if (!options.appendTo.length) {
            throw new Error('Element to append modal to not found in the DOM');
          }

          var modal = Object.assign({
            openedDeferred: $q.defer(),
            closedDeferred: $q.defer(),
            resultDeferred: $q.defer(),
            parent: options.appendTo,
            showOverlay: options.overlay,
            element: angular.element('<div></div>')
          }, options);

          var modalInstance = {
            $$modal: modal,
            name: name,
            opened: modal.openedDeferred.promise,
            closed: modal.closedDeferred.promise,
            result: modal.resultDeferred.promise,
            resolve: function resolve(result) {
              return closeModal(modalInstance, result);
            },
            close: function close(reason) {
              return closeModal(modalInstance, reason, true);
            }
          };

          if (options.closeOnEsc) {
            modal.closeOnEsc = function (event) {
              var key = event.keyCode || event.which;
              if (key === 27 && (!name || $modalStack.isLast(name))) {
                $rootScope.$apply(function () {
                  closeModal(modalInstance, REASON_CANCEL, true);
                });
              }
            };
            $document[0].addEventListener('keydown', modal.closeOnEsc);
          }

          modal.broadcastEnter = function (event) {
            var key = event.keyCode || event.which;
            var isTextarea = event.target.tagName === 'TEXTAREA';
            if (key === 13 && !event.defaultPrevented && !isTextarea) {
              $rootScope.$broadcast('$modalEnterKey', modalInstance, event);
            }
          };
          $document[0].addEventListener('keydown', modal.broadcastEnter);

          $q.all([getTemplatePromise(options.template, options.templateUrl)].concat(_toConsumableArray(getResolvePromises(options.resolve)))).then(function (resolves) {

            modal.content = resolves.shift();

            modal.scope = (options.scope || $rootScope).$new();
            modal.scope.$close = modalInstance.close;
            modal.scope.$resolve = modalInstance.resolve;

            if (options.controller) {
              (function () {

                var locals = {};

                locals.$scope = modal.scope;
                locals.$modalInstance = modalInstance;
                locals.$element = modal.element;

                if (options.locals && _typeof(options.locals) === 'object') {
                  angular.forEach(options.locals, function (value, key) {
                    locals[key] = value;
                  });
                }

                angular.forEach(options.resolve, function (value, key) {
                  locals[key] = resolves.shift();
                });

                modal.controller = $controller(options.controller, locals);
                if (options.controllerAs) {
                  modal.scope[options.controllerAs] = modal.controller;
                }

                angular.forEach(locals, function (value, key) {
                  modal.controller[key] = value;
                });
              })();
            }

            if (closeOthers) {
              Modal.closeAll();
            }

            openModal(modalInstance);
          }).catch(function (reason) {
            modal.openedDeferred.reject(reason);
            modal.resultDeferred.reject(reason);
          });

          return modalInstance;
        },


        closeAll: function closeAll(reason) {
          var stack = $modalStack.get();
          angular.forEach(stack, function (modalInstance) {
            closeModal(modalInstance, reason || REASON_CANCEL, true);
          });
        },


        isOpen: function isOpen(name) {
          return $modalStack.isOpen(name);
        }
      };

      return Modal;
    }];
  });
})(window, window.angular);